import axios from 'axios'
import Bluebird from 'bluebird'
import Konva from 'konva'
import _ from 'lodash'
import randomstring from 'randomstring'
import MockupConstant from '../../../../constant/mockupConstant'
import composite from '../../../../webworkers/helpers/composite'
import {
    ALLOW_ARTWORK_SIDES,
    ALLOW_EXTENSIONS,
    DEFAULT_MOCKUP_COLOR,
    IMAGE_DEFAULT_EFFECTS,
    MAXIMUM_IMAGE_SIZE,
    MAXIMUM_SVG_SIZE,
    MOVE_LAYER_DIRECTION_KEY,
    PREFIX_LOG,
    SCALE_FOR_PREVIEW_DEFAULT,
} from './constants'
// import imageCompression from 'browser-image-compression'
import { Buffer } from 'buffer'
import '../../../../helpers/textAlongPath'
import { uploadArtworkCaptureV2 } from '../../../../services/MockupGeneratorServices'

export const updateHistory = (history, logs) => {
    history.step += 1
    let newHistoryLogs = [...history.logs]
    newHistoryLogs = newHistoryLogs.slice(0, history.step)
    newHistoryLogs.push(logs)
    history.logs = newHistoryLogs
}

export const handleUndo = (state) => {
    const {history, mappedAttributes, pickerAttributes} = state

    let historyStep = history.step
    let historyLogs = history.logs
    let newMappedAttributes = {...mappedAttributes}

    if (historyStep === 0) return state

    historyStep -= 1
    const selectedSide = historyLogs[historyStep].selectedSide
    const newPickerAttributes = historyLogs[historyStep].pickerAttributes
    const pickerVariants = historyLogs[historyStep].pickerVariants
    newMappedAttributes = historyLogs[historyStep].mappedAttributes
    const newDesigns = historyLogs[historyStep].designs

    // find the selected attribute
    const selectedAttribute = pickerAttributes.find((attr) => attr._id === historyLogs[historyStep].selectedAttributeId)

    history.step = historyStep
    hideToolbar()

    return {
        ...state,
        history,
        selectedSide,
        designs: newDesigns,
        selectedLayer: null,
        reloadPreview: true,
        pickerVariants,
        selectedAttribute,
        pickerAttributes: newPickerAttributes,
        mappedAttributes: newMappedAttributes,
    }
}

export const handleRedo = (state) => {
    const {history, mappedAttributes} = state

    let historyStep = history.step
    let historyLogs = history.logs
    let newMappedAttributes = {...mappedAttributes}

    if (historyStep === historyLogs.length - 1) return state

    historyStep += 1

    const newDesigns = historyLogs[historyStep].designs
    const pickerAttributes = historyLogs[historyStep].pickerAttributes
    const pickerVariants = historyLogs[historyStep].pickerVariants
    const selectedSide = historyLogs[historyStep].selectedSide
    newMappedAttributes = historyLogs[historyStep].mappedAttributes

    history.step = historyStep
    hideToolbar()

    return {
        ...state,
        history,
        selectedSide,
        designs: newDesigns,
        selectedLayer: null,
        reloadPreview: true,
        pickerAttributes,
        pickerVariants,
        mappedAttributes: newMappedAttributes,
    }
}

/**
 * convert pixels -> inches
 * @param {Number} pixels
 * @param {Number} dpi - dpi value for most screens
 * @returns {Number}
 */
export function pixelsToInches(layer, size = 'width') {
    const {width, scaleX, height, scaleY, bgDpi} = layer

    const realWidth = Math.abs(width * scaleX)
    const realHeight = Math.abs(height * scaleY)

    const realSize = size === 'width' ? realWidth : realHeight

    return parseFloat(Math.abs(realSize)) / bgDpi
}

export function inchesToPixels(inches, dpi) {
    return parseFloat(inches) * dpi
}

const getImageSizeInBytes = (imgURL) => {
    var request = new XMLHttpRequest()
    request.open('HEAD', imgURL, false)
    request.send(null)
    var headerText = request.getAllResponseHeaders()
    var re = /Content-Length\s*:\s*(\d+)/i
    re.exec(headerText)
    return parseInt(RegExp.$1)
}

const getImageSize = (imageUrl) => {
    return new Promise((resolve, reject) => {
        const img = new Image()

        img.addEventListener('load', () => {
            resolve({width: img.width, height: img.height, size: img.size})
        })

        img.addEventListener('error', () => {
            reject(new Error('Failed to load image'))
        })

        img.src = imageUrl
    })
}

/**
 * @param {*} file
 * @param {number} [maxWidth=60]
 * @param {number} [maxHeight=60]
 */
function resizeImage(file, maxWidth = 60, maxHeight = 60) {
    return new Promise((resolve) => {
        let img = new Image()
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (event) => {
            img.src = event.target.result
            img.onload = () => {
                const naturalWidth = img.width
                const naturalHeight = img.height
                let width = naturalWidth
                let height = naturalHeight
                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width
                        width = maxWidth
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height
                        height = maxHeight
                    }
                }
                let canvas = document.createElement('canvas')
                canvas.width = width
                canvas.height = height
                let ctx = canvas.getContext('2d')
                ctx.drawImage(img, 0, 0, width, height)
                canvas.toBlob((blob) => {
                    resolve({
                        tinyFile: blob,
                        naturalWidth,
                        naturalHeight,
                    })
                }, file.type)
            }
        }
    })
}

const fileCache = new Map()

async function checkBlob(blobUrl) {
    try {
        const response = await fetch(blobUrl)
        if (response.ok) {
            return true // Blob exists
        } else {
            return false // Blob does not exist
        }
    } catch (error) {
        return false
    }
}

async function parseImageFile(file) {
    const imageSize = file.size
    const imageName = file.name
    const imageType = file.type
    const keyString = `${imageName}-${imageSize}-${file.lastModified}`

    let blobUrl = fileCache.get(keyString)
    if (!blobUrl) {
        blobUrl = createBlob(file)
        fileCache.set(keyString, blobUrl)
    } else {
        const isExists = await checkBlob(blobUrl)
        if (!isExists) {
            fileCache.delete(keyString)
            return parseImageFile(file)
        }
    }

    return {
        blobUrl,
        imageSize,
        imageName,
        imageType,
    }
}

export function handleCalculateDpi(layer) {
    if (layer.dpi === Infinity) return Infinity
    const {scaleX, scaleY, bgDpi} = layer

    const dpiWidth = bgDpi / scaleX
    const dpiHeight = bgDpi / scaleY

    return Math.round(Math.abs(Math.min(dpiWidth, dpiHeight)))
}

export function hideToolbar() {
    const textToolbar = document.getElementById('text-toolbar')
    const imageToolbar = document.getElementById('image-toolbar')
    if (textToolbar) textToolbar.style.display = 'none'
    if (imageToolbar) imageToolbar.style.display = 'none'
}

export function roundDecimal(number, decimalPlaces) {
    return decimalPlaces ? +number.toFixed(decimalPlaces) : +number.toFixed(0)
}

export function updateToolbarStyle(areaPosition = {x: 0, y: 0, width: 0, height: 0, rotation: 0}, toolbarId = '') {
    const toolbar = document.getElementById(toolbarId)
    if (!toolbar) return
    if (toolbarId === 'image-toolbar') {
        const textToolbar = document.getElementById('text-toolbar')
        if (textToolbar) textToolbar.style.display = 'none'
    } else {
        const imageToolbar = document.getElementById('image-toolbar')
        if (imageToolbar) imageToolbar.style.display = 'none'
    }
    toolbar.style.display = 'flex'
    // const toolbarWidth = toolbar.offsetWidth
    // const radian = areaPosition.rotation * (Math.PI / 180)
    // let top = Math.cos(radian) >= 0 ? areaPosition.y - 80 : areaPosition.y + 20
    // let left = areaPosition.x - toolbarWidth / 2
    // const limitLeftX = 200
    // const limitRightX = 800
    // const limitTopY = 150
    // const limitBottomY = 600
    //
    // if (areaPosition.x < limitLeftX) {
    //     left = areaPosition.x + areaPosition.width * 2
    // } else if (areaPosition.x > limitRightX) {
    //     left = areaPosition.x - areaPosition.width * 2
    // }
    // if (areaPosition.y < limitTopY) {
    //     if (areaPosition.y < 0) {
    //         top = areaPosition.height - limitTopY / 3
    //     } else {
    //         top =
    //             toolbarId === 'text-toolbar'
    //                 ? areaPosition.y + areaPosition.height - limitTopY / 3
    //                 : areaPosition.y + areaPosition.height * 2 + limitTopY / 4
    //     }
    // } else if (areaPosition.y > limitBottomY) {
    //     top =
    //         areaPosition.y +
    //         (toolbarId === 'text-toolbar'
    //             ? areaPosition.height - limitBottomY / 6
    //             : areaPosition.height - limitBottomY / 3)
    // }
    //
    // if (top > 800) top = 800
    //
    // toolbar.style.top = top + 'px'
    // toolbar.style.left = left + 'px'
}

export const convertBase64ToBlob = (base64, type = 'png') => {
    const binaryData = Buffer.from(base64.split(',')[1], 'base64')
    return new Blob([binaryData], {type: `image/${type}`})
}

/**
 * calculate the ratio by size of the components and object resized accordingly
 * @param {object} targetSize
 * @param {number} targetSize.width
 * @param {number} targetSize.height
 * @param {object} currentSize
 * @param {number} currentSize.width
 * @param {number} currentSize.height
 * @param {object|undefined} options
 * @param {number} options.distance
 * @returns
 */
export const calcRatioDefault = (targetSize, currentSize, options) => {
    const {distance = 0} = options || {}
    const widthRatio = (targetSize.width - distance) / currentSize.width
    const heightRatio = (targetSize.height - distance) / currentSize.height
    return Math.min(widthRatio, heightRatio)
}

export const downloadJSON = (data, filename) => {
    const blob = new Blob([data], {type: 'application/json'})
    const url = createBlob(blob)
    downloadFile(url, filename)
}

export const downloadFile = (file, filename = 'Mockup') => {
    const link = document.createElement('a')
    link.href = file
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export const initTextActualSize = (textAttrs) => {
    const {tr} = initVirtualTransformer({
        text: textAttrs.text,
        fontSize: Math.ceil(textAttrs.fontSize),
        align: textAttrs.align,
        fontFamily: textAttrs.fontFamily,
        draggable: false,
        x: textAttrs.x,
        y: textAttrs.y,
        rotation: textAttrs.rotation,
    })
    const {width, height} = tr.getSize()
    tr.destroy()
    return {
        width,
        height,
    }
}

export const initVirtualTransformer = (textOptions) => {
    const cloneText = new Konva.Text(textOptions)
    // Re get width and height from transformer
    const tr = new Konva.Transformer({
        nodes: [cloneText],
    })
    return {cloneText, tr}
}

export const createBlob = (file) => {
    return URL.createObjectURL(file)
}
export const replaceUnderline = (str = '') => {
    if (str.includes('_')) {
        str = str.replaceAll('_', ' ')
    }
    if (str.includes('-')) {
        str = str.replaceAll('-', ' ')
    }
    return str
}

export const validateUploadedFileMessage = (fileName, fileSize) => {
    let message = ''
    const parts = fileName.split('.')
    const fileExtension = parts[parts.length - 1].toLowerCase()
    if (ALLOW_EXTENSIONS.includes(fileExtension)) {
        if (
            (['jpg', 'png'].includes(fileExtension) && fileSize > MAXIMUM_IMAGE_SIZE) ||
            (fileExtension === 'svg' && fileSize > MAXIMUM_SVG_SIZE)
        ) {
            message = `Maximum ${MAXIMUM_IMAGE_SIZE / 1024 / 1024} MB (JPG, PNG) or ${
                MAXIMUM_SVG_SIZE / 1024 / 1024
            } MB (SVG)`
        }
    } else {
        message = 'JPG, PNG and SVG file types supported'
    }
    return message
}

export const handleUploadFile = async (file) => {
    const validateMessage = validateUploadedFileMessage(file.name, file.size)
    if (validateMessage) {
        throw new Error(validateMessage)
    }

    const [{blobUrl, imageSize, imageName, imageType}, {tinyFile, naturalWidth, naturalHeight}] = await Promise.all([
        parseImageFile(file),
        resizeImage(file),
    ])

    const imageObj = {
        src: blobUrl,
        name: imageName,
        naturalSize: imageSize,
        imageType,
        naturalWidth,
        naturalHeight,
    }

    const isSvg = imageType === 'image/svg+xml'
    if (!isSvg) {
        const {blobUrl: tinyBlobUrl} = await parseImageFile(tinyFile)
        imageObj.tinySrc = tinyBlobUrl
    }

    return imageObj
}

export const handleUploadImageByLink = async (imageUrl, imageName) => {
    const {width: naturalWidth, height: naturalHeight} = await getImageSize(imageUrl)
    const size_image = await getImageSizeInBytes(imageUrl)

    return {
        _id: randomstring.generate(7),
        src: imageUrl,
        name: imageName,
        size: size_image,
        naturalSize: size_image,
        imageType: '',
        width: naturalWidth,
        height: naturalHeight,
        naturalWidth,
        naturalHeight,
        created: new Date(),
    }
}

export const turnCmykToHex = (cmyk) => {
    const {c, m, y, k} = cmyk
    // Convert the CMYK values to RGB
    const r = 255 * (1 - c / 100) * (1 - k / 100)
    const g = 255 * (1 - m / 100) * (1 - k / 100)
    const b = 255 * (1 - y / 100) * (1 - k / 100)

    // Convert the RGB values to hex
    const hex = `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`

    // Return the hex color value
    return hex
}

export const turnHexToCmyk = (hex) => {
    // Convert the hex color value to RGB
    const r = parseInt(hex.substring(1, 3), 16)
    const g = parseInt(hex.substring(3, 5), 16)
    const b = parseInt(hex.substring(5, 7), 16)

    // Normalize the RGB values
    const [rn, gn, bn] = [r / 255, g / 255, b / 255]

    // Find the maximum value of the normalized RGB values
    const max = Math.max(rn, gn, bn)

    // If the maximum value is 0, return 0 for all CMYK values
    if (max === 0) {
        return {c: 0, m: 0, y: 0, k: 1}
    }

    // Calculate the K value
    const k = 1 - max

    // Calculate the C, M, and Y values
    const c = (1 - rn - k) / (1 - k)
    const m = (1 - gn - k) / (1 - k)
    const y = (1 - bn - k) / (1 - k)

    // Return the CMYK values
    return {c: Math.round(c * 100), m: Math.round(m * 100), y: Math.round(y * 100), k: Math.round(k * 100)}
}

const genAttributes = (product) => {
    const {attributes: productAttributes, mockups} = product

    const typeAttributeSets = new Set(productAttributes.filter(({customized}) => customized).map(({type}) => type))
    const uniqueAttributesMap = new Map()
    const mockupGrouped = _.groupBy(mockups, '_id')

    const variants = product.variants.map((variant) => {
        const {mockup: mockupId, attributes: variantAttributes, sku, _id} = variant
        const filteredAttributes = variantAttributes.filter(({type}) => typeAttributeSets.has(type))
        const options = filteredAttributes.map(({value_text}) => value_text)
        const key = options.join('---')

        if (!uniqueAttributesMap.has(key)) {
            const mockups = mockupGrouped[mockupId]
            if (!Array.isArray(mockups) || !mockups.length)
                return {
                    ...variant,
                    disabled: true,
                }

            const mockup = mockups[0]
            uniqueAttributesMap.set(key, {
                attributes: filteredAttributes,
                key,
                mockup,
                listSku: [sku],
                variantIds: [_id],
                options,
            })
        } else {
            uniqueAttributesMap.set(key, {
                ...uniqueAttributesMap.get(key),
                listSku: [...uniqueAttributesMap.get(key).listSku, sku],
                variantIds: [...uniqueAttributesMap.get(key).variantIds, _id],
            })
        }

        return {
            ...variant,
            disabled: false,
        }
    })

    // reference
    product.variants = variants

    const uniqueAttributes = Array.from(uniqueAttributesMap.values())
    return uniqueAttributes
}

const genMappedAttributes = (pickerAttributes, selectedAttribute) => {
    const mappedAttributes = {}
    pickerAttributes.forEach(({attributes}) => {
        const is_selected = _.isEqual(selectedAttribute.attributes, attributes)
        attributes.forEach((_attr) => {
            const attr = {..._attr}
            const {type, value_code} = attr
            attr.is_selected = is_selected
            attr.value = value_code
            if (!mappedAttributes[type]) {
                mappedAttributes[type] = []
            }
            const existingAttribute = mappedAttributes[type].find((a) => a.value === value_code)
            if (!existingAttribute) {
                mappedAttributes[type].push(attr)
            }
        })
    })

    return mappedAttributes
}

export const handleInitAppliedVariants = (state) => {
    const generateDpi = (dpi = {}) => {
        return dpi.minimum || roundDecimal(Math.min(dpi.height, dpi.width), 0) || 72
    }

    const {editorWrapper, product} = state
    if (!product) {
        return {
            ...state,
        }
    }

    const {width: editorWidth, height: editorHeight} = editorWrapper
    const centerXEditor = editorWidth / 2
    const centerYEditor = editorHeight / 2
    const distance = Math.min(editorWidth, editorHeight) / 4

    const productAttributes = genAttributes(product)
    const side = {}

    const attributeMockups = productAttributes.map((attribute) => {
        const {
            dpi,
            preview_v2,
            preview_v3,
            preview_meta: {default_material_color, scale_preview_ratio},
        } = attribute.mockup
        const minDpi = generateDpi(dpi)
        const previewVersion = attribute.mockup.meta.version

        const fill =
            default_material_color === undefined
                ? '#ffffff'
                : !default_material_color
                  ? 'transparent'
                  : default_material_color

        const ref = {}
        const safeZone = {}

        for (const key in preview_v2) {
            const {
                position: {left, top},
                size: {width, height},
                template_url,
            } = preview_v2[key]

            const ratioDefault = calcRatioDefault(editorWrapper, {width, height}, {distance})

            const centerBgWidth = width / 2
            const centerBgHeight = height / 2
            const bgX = centerXEditor / ratioDefault - centerBgWidth
            const bgY = centerYEditor / ratioDefault - centerBgHeight

            if (!side[key]) side[key] = {layers: [], histories: [], historyStep: 0}
            ref[key] = null
            safeZone[key] = {
                src: previewVersion === 'v3' ? preview_v3.template_configs[key].url : template_url,
                x: bgX,
                y: bgY,
                width: width,
                height: height,
                left,
                top,
                isBackground: true,
                rotation: 0,
                scaleX: 1,
                scaleY: 1,
                fill,
                dpi: minDpi,
                ratioDefault,
                invalidsDpi: [],
                cachedArtworks: [],
                cached: {
                    preview: null,
                    publish: null,
                },
            }
        }

        const isCached = {
            preview: false,
            publish: false,
        }

        return {
            ...attribute,
            safeZone,
            ref,
            _id: randomstring.generate(24),
            isCached,
            scalePreviewRatio: scale_preview_ratio || SCALE_FOR_PREVIEW_DEFAULT,
        }
    })

    const selectedAttribute = attributeMockups[0]
    const pickerAttributes = attributeMockups
    const selectedSide = Object.keys(selectedAttribute.safeZone)[0]
    const linkedAttrIds = pickerAttributes.map((a) => a._id)

    const designs = [
        {
            allAttrIds: attributeMockups.map((attribute) => attribute._id),
            linkedAttrIds,
            side,
            isDefault: true,
        },
    ]

    const isSizeType = (attribute) => attribute.type === 'size'
    const getAttributesValues = (attribute) => attribute.values
    const valuesOfAttributesIsNotSizeType = (attributes) =>
        attributes.filter((attr) => !isSizeType(attr)).map(getAttributesValues)
    const getFirstAttributeValues = (attributes) => attributes.map((attribute) => attribute[0].code)

    const firstAttributeValues = getFirstAttributeValues(valuesOfAttributesIsNotSizeType(product.attributes))

    function haveSameElements(arr1, arr2) {
        if (arr1.length !== arr2.length) return false
        const set1 = new Set(arr1)
        const set2 = new Set(arr2)
        return [...set1].every((item) => set2.has(item))
    }

    const temp = product.variants.filter((v) =>
        haveSameElements(
            firstAttributeValues,
            v.attributes.filter((att) => !isSizeType(att)).map((a) => a.value_code),
        ),
    )
    const pickerVariants = [...temp.flat(1)]

    const mappedAttributes = genMappedAttributes(pickerAttributes, selectedAttribute)

    function transformObject(data) {
        const result = {}

        Object.keys(data).forEach((key) => {
            if (key === 'size') {
                result[key] = data[key]
            } else {
                result[key] = [data[key][0]]
            }
        })
        return result
    }

    const history = {
        step: 0,
        logs: [
            {
                designs,
                selectedSide,
                mappedAttributes: {...transformObject(mappedAttributes)},
                pickerAttributes,
                pickerVariants,
                selectedAttributeId: selectedAttribute._id,
            },
        ],
    }

    return {
        ...state,
        designs,
        selectedSide, // current side design selected
        variants: product.variants, // all variants
        attributes: product.attributes, // all attributes
        selectedAttribute, // current attribute selected
        pickerAttributes, // list attributes has picked
        pickerVariants, // list variants has picked
        // mappedAttributes, // attributes for select box
        mappedAttributes: {...transformObject(mappedAttributes)},
        attributeMockups, // attributes transform from mockup
        history,
    }
}

export const handleCreateStageRef = (state, payload) => {
    const {pickerAttributes} = state
    const {side, stageRef, attributeIds} = payload

    for (const pickerAttr of pickerAttributes) {
        if (!attributeIds.includes(pickerAttr._id)) continue

        const ref = pickerAttr.ref[side]
        if (!ref) {
            pickerAttr.ref[side] = stageRef
        }
    }

    return {...state, pickerAttributes}
}

const resetZoom = (state) => {
    const {selectedAttribute, selectedSide} = state

    if (!selectedAttribute || !selectedSide) return

    const sides = Object.keys(selectedAttribute.safeZone)
    if (!sides.includes(selectedSide)) return

    const ref = selectedAttribute.ref[selectedSide]
    const background = selectedAttribute.safeZone[selectedSide]

    if (!ref || !background) return

    const {ratioDefault} = background
    ref.scale({x: ratioDefault, y: ratioDefault})
    ref.position({x: 0, y: 0})
    ref.batchDraw()
}

export const handleChangeActiveSide = (state, newSide) => {
    resetZoom(state)

    const {history} = state
    const latestSide = history.logs[history.logs.length - 1].selectedSide
    if (!(newSide === 'All' || newSide === latestSide)) {
        history.step += 1
        history.logs.push({...history.logs[history.logs.length - 1], selectedSide: newSide})
    }

    return {
        ...state,
        selectedSide: newSide,
        selectedLayer: null,
        history,
    }
}

export const handleChangeSelectedAttribute = (state, {key, data}) => {
    const {designs, pickerAttributes, pickerVariants, mappedAttributes, selectedAttribute, history} = state

    const newMappedAttributes = {
        ...mappedAttributes,
        [key]: mappedAttributes[key].map((attribute) => ({
            ...attribute,
            is_selected: attribute.value === data.value,
        })),
    }

    const selected = []
    Object.entries(newMappedAttributes).forEach(([key, values]) => {
        const value = values.find((v) => v.is_selected)
        if (value) {
            selected.push({
                type: value.type,
                value_code: value.value_code,
            })
        }
    })

    const newSelectedAttribute = pickerAttributes.find((picker) => {
        const {attributes} = picker
        const reduceAttributes = attributes.map((attr) => ({
            type: attr.type,
            value_code: attr.value_code,
        }))

        return _.isEqual(selected, reduceAttributes)
    })

    if (!newSelectedAttribute || newSelectedAttribute._id === selectedAttribute?._id) return {...state}

    resetZoom(state)
    const selectedSide = Object.keys(newSelectedAttribute.safeZone)[0]

    const logs = {
        designs,
        selectedSide,
        mappedAttributes: newMappedAttributes,
        pickerAttributes,
        pickerVariants,
        selectedAttributeId: newSelectedAttribute._id,
    }
    updateHistory(history, logs)

    return {
        ...state,
        ...logs,
        selectedAttribute: newSelectedAttribute,
        selectedLayer: null,
        selectedSide,
        history,
    }
}

const handleAddNewLayer = (state, newLayer) => {
    const {selectedAttribute, selectedSide, pickerAttributes, pickerVariants, designs, history, mappedAttributes} =
        state
    const attrId = selectedAttribute._id

    const newDesigns = JSON.parse(JSON.stringify(designs)).map((design) => {
        const {linkedAttrIds} = design
        if (linkedAttrIds.includes(attrId)) {
            design.side[selectedSide].layers.push(newLayer)
            handleCleanUpCacheCapture(state, {sides: [selectedSide], attributeIds: linkedAttrIds})
        }

        return design
    })

    const logs = {
        designs: newDesigns,
        mappedAttributes,
        selectedSide,
        pickerVariants,
        pickerAttributes,
        selectedAttributeId: selectedAttribute._id,
    }

    updateHistory(history, logs)

    return {
        ...state,
        designs: newDesigns,
        selectedLayer: newLayer,
        reloadPreview: true,
        history,
    }
}

export const handleAddTextLayer = (state) => {
    const {selectedAttribute, selectedSide} = state
    const background = selectedAttribute.safeZone[selectedSide]
    const {x: bgX, y: bgY, width: bgWidth, height: bgHeight, ratioDefault} = background

    const newText = {
        ...MockupConstant.DEFAULT_TEXT_ELEMENT,
        id: Date.now().toString(),
        x: bgWidth / 2 + bgX,
        y: bgHeight / 2 + bgY,
        fontSize: 32 / ratioDefault,
    }

    const {width, height} = initTextActualSize(newText)

    newText.width = width
    newText.height = height
    newText.offsetX = width / 2
    newText.offsetY = height / 2

    return handleAddNewLayer(state, newText)
} //node

export const handleAddImageLayer = (state, imageObject) => {
    const {selectedAttribute, selectedSide} = state

    const background = selectedAttribute.safeZone[selectedSide]

    const {x: bgX, y: bgY, width: bgWidth, height: bgHeight, dpi: bgDpi} = background

    const {src, tinySrc, name, naturalWidth, naturalHeight, imageType} = imageObject

    let scale = 1

    const isSvg = imageType === 'image/svg+xml'

    const minSize = Math.min(background.width, background.height) / 5
    if (naturalWidth > bgWidth || naturalHeight > bgHeight) {
        const widthRatio = bgWidth / naturalWidth
        const heightRatio = bgHeight / naturalHeight
        scale = Math.min(widthRatio, heightRatio)
    } else if (isSvg && (naturalWidth < minSize || naturalHeight < minSize)) {
        if (naturalWidth < naturalHeight) {
            const ratio = naturalWidth / naturalHeight
            const newWidth = minSize
            const newHeight = minSize + minSize * ratio

            const widthRatio = newWidth / naturalWidth
            const heightRatio = newHeight / naturalHeight
            scale = Math.min(widthRatio, heightRatio)
        } else {
            const ratio = naturalWidth / naturalHeight
            const newWidth = minSize + minSize * ratio
            const newHeight = minSize

            const widthRatio = newWidth / naturalWidth
            const heightRatio = newHeight / naturalHeight
            scale = Math.min(widthRatio, heightRatio)
        }
    }

    const newLayer = {
        id: Date.now().toString(),
        src,
        tinySrc: tinySrc || src,
        text: name,
        width: naturalWidth,
        height: naturalHeight,
        draggable: true,
        layerType: 'image',
        isLock: false,
        rotation: 0,
        scaleX: scale,
        scaleY: scale,
        offsetX: naturalWidth / 2,
        offsetY: naturalHeight / 2,
        x: bgWidth / 2 + bgX,
        y: bgHeight / 2 + bgY,
        bgDpi,
        imageType,
        ...IMAGE_DEFAULT_EFFECTS,
    }
    if (isSvg) newLayer.dpi = Infinity

    return handleAddNewLayer(state, newLayer)
}

function arrayUpsertInvalidDpi(array, layer) {
    const index = array.findIndex((item) => item.id === layer.id)

    if (index === -1) {
        array.push(layer)
    } else {
        array[index] = layer
    }

    return array
}

export const handleUpdateLayerAttribute = (state, layer, needUpdateHistory) => {
    const start = performance.now()
    const {selectedAttribute, selectedSide, designs, pickerAttributes, pickerVariants, history, mappedAttributes} =
        state

    const attrId = selectedAttribute._id

    const newDesigns = JSON.parse(JSON.stringify(designs)).map((design) => {
        const {linkedAttrIds, side} = design

        if (linkedAttrIds.includes(attrId)) {
            const updatedDesign = {...design}

            // if (layer.layerType === 'text' && !layer.text?.trim()) {
            //     updatedDesign.side[selectedSide].layers = side[selectedSide].layers.filter((item) => {
            //         return layer.id !== item.id
            //     })

            //     return updatedDesign
            // }

            updatedDesign.side[selectedSide].layers = side[selectedSide].layers.map((item) => {
                if (layer.id !== item.id) return item

                return {...layer}
            })

            handleCleanUpCacheCapture(state, {sides: [selectedSide], attributeIds: linkedAttrIds})

            if (layer.layerType === 'image' && layer.imageType !== 'image/svg+xml') {
                const dpi = handleCalculateDpi(layer)
                const _pickerAttributes = pickerAttributes.filter((attr) => linkedAttrIds.includes(attr._id))

                for (const attr of _pickerAttributes) {
                    const background = attr.safeZone[selectedSide]
                    if (!background) continue

                    const bgDpi = background.dpi

                    if (dpi < bgDpi) {
                        background.invalidsDpi = arrayUpsertInvalidDpi(background.invalidsDpi, layer)
                    } else {
                        background.invalidsDpi = background.invalidsDpi.filter((invalid) => invalid.id !== layer.id)
                    }
                }
            }

            return updatedDesign
        }

        return design
    })

    if (needUpdateHistory) {
        const logs = {
            designs: newDesigns,
            mappedAttributes,
            selectedSide,
            pickerVariants,
            pickerAttributes,
            selectedAttributeId: attrId,
        }
        updateHistory(history, logs)
    }

    const end = performance.now()
    console.log('update layer attribute', end - start)
    return {
        ...state,
        designs: newDesigns,
        selectedLayer: layer,
        reloadPreview: true,
        history,
    }
}

export const handleRemoveLayer = (state, layer) => {
    const {
        selectedAttribute,
        pickerVariants,
        selectedSide,
        designs,
        pickerAttributes,
        history,
        mappedAttributes,
        tabDesign,
    } = state

    const attrId = selectedAttribute._id

    const newDesigns = JSON.parse(JSON.stringify(designs)).map((design) => {
        const {linkedAttrIds, side} = design

        if (linkedAttrIds.includes(attrId)) {
            const newLayer = side[selectedSide].layers.filter((item) => {
                return layer.id !== item.id
            })

            design.side[selectedSide].layers = newLayer
            handleCleanUpCacheCapture(state, {sides: [selectedSide], attributeIds: linkedAttrIds})

            if (layer.layerType === 'image' && layer.imageType !== 'image/svg+xml') {
                const _pickerAttributes = pickerAttributes.filter((variant) => linkedAttrIds.includes(variant._id))
                for (const variant of _pickerAttributes) {
                    const background = variant.safeZone[selectedSide]
                    if (!background) continue

                    background.invalidsDpi = background.invalidsDpi?.filter((invalid) => invalid.id !== layer.id)
                }
            }
        }

        return design
    })

    const newLayers = newDesigns.length > 0 ? newDesigns[0].side[selectedSide].layers : []

    const logs = {
        designs: newDesigns,
        mappedAttributes,
        selectedSide,
        pickerVariants,
        pickerAttributes,
        tabDesign: tabDesign === 'layer' ? (newLayers.length === 0 ? '' : 'layer') : tabDesign,
    }

    updateHistory(history, logs)
    hideToolbar()

    return {
        ...state,
        designs: newDesigns,
        selectedLayer: null,
        reloadPreview: true,
        history,
        selectedAttributeId: attrId,
        tabDesign: tabDesign === 'layer' ? (newLayers.length === 0 ? '' : 'layer') : tabDesign,
    }
}

export const handleDuplicateLayer = (state, payload) => {
    const {selectedAttribute, selectedSide, designs, pickerAttributes, pickerVariants, history, mappedAttributes} =
        state
    const {
        currLayer,
        options: {scopeAll},
    } = payload

    const _design = JSON.parse(JSON.stringify(designs))

    if (scopeAll) {
        const newDesigns = _design.map((design) => {
            const {linkedAttrIds, side} = design

            if (linkedAttrIds.includes(selectedAttribute._id)) {
                const sidesChange = []

                for (const attrId of linkedAttrIds) {
                    if (selectedAttribute._id === attrId) {
                        for (const key in side) {
                            if (key === selectedSide) continue

                            const background = selectedAttribute.safeZone[key]
                            if (!background) continue

                            if (!sidesChange.includes(key)) sidesChange.push(key)

                            const {x: bgX, y: bgY, width: bgWidth, height: bgHeight, dpi: bgDpi} = background

                            const x = bgWidth / 2 + bgX
                            const y = bgHeight / 2 + bgY

                            const newLayer = {...currLayer, x, y, id: Date.now().toString(), isLock: false}

                            design.side[key].layers.push(newLayer)

                            if (newLayer.layerType === 'image' && currLayer.imageType !== 'image/svg+xml') {
                                newLayer.bgDpi = bgDpi
                                const dpi = handleCalculateDpi(newLayer)

                                if (dpi < bgDpi) {
                                    background.invalidsDpi = arrayUpsertInvalidDpi(background.invalidsDpi, newLayer)
                                }
                            }
                        }
                        continue
                    }
                }

                handleCleanUpCacheCapture(state, {sides: sidesChange, attributeIds: linkedAttrIds})
            }

            return design
        })

        const logs = {
            designs: newDesigns,
            mappedAttributes,
            selectedSide,
            pickerVariants,
            pickerAttributes,
            selectedAttributeId: selectedAttribute._id,
        }

        updateHistory(history, logs)

        return {
            ...state,
            designs: newDesigns,
            reloadPreview: true,
            history,
        }
    }

    const newLayer = {
        ...currLayer,
        id: Date.now().toString(),
        isLock: false,
        x: currLayer.x + 30,
        y: currLayer.y + 30,
    }

    const newDesigns = _design.map((design) => {
        const {linkedAttrIds} = design

        if (linkedAttrIds.includes(selectedAttribute._id)) {
            design.side[selectedSide].layers.push(newLayer)
            handleCleanUpCacheCapture(state, {sides: [selectedSide], attributeIds: linkedAttrIds})

            if (newLayer.layerType === 'image' && newLayer.imageType !== 'image/svg+xml') {
                const dpi = handleCalculateDpi(newLayer)
                const _pickerAttributes = pickerAttributes.filter((variant) => linkedAttrIds.includes(variant._id))
                for (const variant of _pickerAttributes) {
                    const background = variant.safeZone[selectedSide]
                    if (!background) continue

                    const bgDpi = background.dpi

                    if (dpi < bgDpi) {
                        background.invalidsDpi = arrayUpsertInvalidDpi(background.invalidsDpi, newLayer)
                    }
                }
            }
        }

        return design
    })

    const logs = {
        designs: newDesigns,
        mappedAttributes,
        selectedSide,
        pickerVariants,
        pickerAttributes,
        selectedAttributeId: selectedAttribute._id,
    }

    updateHistory(history, logs)

    return {
        ...state,
        designs: newDesigns,
        selectedLayer: newLayer,
        reloadPreview: true,
        history,
    }
}

export const checkImageUploadLimit = (layers = []) => {
    const listImageLayers = layers.filter((layer) => layer.layerType === 'image')
    return listImageLayers.length >= 5
}

export const handleMoveLayers = (state, sortedLayers) => {
    const {selectedAttribute, selectedSide, designs, pickerAttributes, pickerVariants, history, mappedAttributes} =
        state
    const attrId = selectedAttribute._id

    const newDesigns = JSON.parse(JSON.stringify(designs)).map((design) => {
        const {linkedAttrIds} = design
        if (linkedAttrIds.includes(attrId)) {
            handleCleanUpCacheCapture(state, {sides: [selectedSide], attributeIds: linkedAttrIds})
            design.side[selectedSide].layers = sortedLayers
        }
        return design
    })

    const logs = {
        designs: newDesigns,
        pickerAttributes,
        pickerVariants,
        mappedAttributes,
        selectedSide,
        selectedAttributeId: attrId,
    }
    updateHistory(history, logs)

    return {
        ...state,
        designs: newDesigns,
        selectedLayer: null,
        reloadPreview: true,
    }
}

export const getErrorRetailPrice = (value = 0, base_cost) => {
    if (value === 0) {
        return 'Retail price must be greater than 0'
    }
    if (value < base_cost) {
        return 'Retail price must be greater than base cost'
    }
    return ''
}

export const handleChangeBgColor = (state, color) => {
    const {selectedAttribute, designs, pickerAttributes, selectedSide, applyBgAllSide} = state

    const design = designs.find((d) => d.linkedAttrIds.includes(selectedAttribute._id))

    const sidesChange = Object.keys(design.side)
    const linkedAttrIds = design.linkedAttrIds

    const linkedAttrs = pickerAttributes.filter((attr) => linkedAttrIds.includes(attr._id))

    linkedAttrs.forEach((attr) => {
        if (applyBgAllSide) {
            for (const background of Object.values(attr.safeZone)) {
                background.fill = color
            }
        } else {
            const valuesSide = attr.safeZone[selectedSide] || []
            valuesSide.fill = color
        }
    })

    handleCleanUpCacheCapture(state, {sides: sidesChange, attributeIds: linkedAttrIds})
    return {...state, reloadPreview: true}
}

export const handleChangeBgImage = (state, bgImage) => {
    return {...state, currentBgImage: bgImage}
}

export const handleSetLoadingBackground = (state, isLoading) => {
    return {...state, reloadBgPreview: isLoading}
}

export const handleSetTabDesign = (state, type) => {
    return {...state, tabDesign: type}
}

export const handleChangeBgImageType = (state, type) => {
    return {...state, currentBgImageType: type}
}

export const handleChangeBgImageRef = (state, base64) => {
    return {...state, currentBgImageBase64: base64}
}

export const handleUpdateListBackgroundImages = (state, bgImages) => {
    return {...state, listBackgroundImages: bgImages}
}

export const handleChangeApplyAllPlacements = (state, applyBgAllSide) => {
    const {selectedAttribute, designs, pickerAttributes, selectedSide} = state

    const design = designs.find((d) => d.linkedAttrIds.includes(selectedAttribute._id))

    const sidesChange = Object.keys(design.side)
    const linkedAttrIds = design.linkedAttrIds

    const linkedAttrs = pickerAttributes.filter((attr) => linkedAttrIds.includes(attr._id))

    linkedAttrs.forEach((attr) => {
        if (applyBgAllSide) {
            const currentBgColor = attr.safeZone[selectedSide].fill
            for (const background of Object.values(attr.safeZone)) {
                background.fill = currentBgColor
            }
        }
    })

    handleCleanUpCacheCapture(state, {sides: sidesChange, attributeIds: linkedAttrIds})

    return {
        ...state,
        applyBgAllSide,
        reloadPreview: true,
    }
}

export const handleChangeLinkedVariant = (state, linked) => {
    const {selectedAttribute, designs, pickerAttributes, pickerVariants, history, mappedAttributes, selectedSide} =
        state
    const attrId = selectedAttribute?._id

    const _design = JSON.parse(JSON.stringify(designs))

    if (linked) {
        const designUpdate = _design
            .map((design) => {
                if (design.linkedAttrIds.includes(attrId)) {
                    return null
                }

                if (design.isDefault) {
                    const sidesChange = Object.keys(design.side)

                    const {safeZone} =
                        pickerAttributes.find((attr) => [...design.linkedAttrIds].includes(attr._id)) || {}
                    if (safeZone) {
                        const color = Object.values(safeZone)[0]?.fill

                        if (color) {
                            const attr = pickerAttributes.find((attr) => attrId === attr._id)
                            for (const background of Object.values(attr.safeZone)) {
                                background.fill = color
                            }
                        }
                    }

                    design.linkedAttrIds.push(attrId)

                    handleCleanUpCacheCapture(state, {sides: sidesChange, attributeIds: [attrId]})
                }
                return design
            })
            .filter(Boolean)

        for (const attribute of pickerAttributes) {
            if (attribute._id !== attrId) continue

            const {ref} = attribute
            Object.keys(ref).forEach((side) => (ref[side] = null))
        }

        return {
            ...state,
            designs: designUpdate,
            reloadPreview: true,
        }
    }

    let newDesign = {}
    const designUpdate = _design.map((design) => {
        const {linkedAttrIds, isDefault} = design
        if (linkedAttrIds.includes(attrId)) {
            if (isDefault) {
                design.linkedAttrIds = linkedAttrIds.filter((linkedVariantId) => linkedVariantId !== attrId)

                const sidesChange = Object.keys(design.side)
                handleCleanUpCacheCapture(state, {sides: sidesChange, attributeIds: [attrId]})

                newDesign = {
                    ...design,
                    isDefault: false,
                    linkedAttrIds: [attrId],
                }
            }
            return design
        }
        return design
    })

    designUpdate.push(newDesign)

    const logs = {
        designs: designUpdate,
        mappedAttributes,
        selectedSide,
        pickerVariants,
        pickerAttributes,
        selectedAttributeId: attrId,
    }
    updateHistory(history, logs)

    const linkedAttrIds = _design.find((item) => item.isDefault)?.linkedAttrIds
    const unlinkedAttrs = pickerAttributes.filter((attr) => !linkedAttrIds.includes(attr._id))
    for (const attribute of unlinkedAttrs) {
        const {ref} = attribute
        Object.keys(ref).forEach((side) => (ref[side] = null))
    }

    return {
        ...state,
        designs: designUpdate,
        reloadPreview: true,
        history,
    }
}

export const handleUpdatePickerVariants = (state, variantIds) => {
    const {variants, designs, attributeMockups, pickerAttributes, selectedAttribute, history} = state

    const _variantIds = variantIds.length ? variantIds : [variants[0]._id]

    const newPickerVariants = variants.filter((variant) => _variantIds.includes(variant._id))
    const newPickerAttributes = attributeMockups.filter((attr) =>
        attr.variantIds.some((id) => _variantIds.includes(id)),
    )

    const deletedAttrIds = pickerAttributes
        .filter((attr) => !newPickerAttributes.find((newAttr) => newAttr._id === attr._id))
        .map((deletedAttr) => deletedAttr._id)

    const addedAttrIds =
        variantIds.length === newPickerVariants.length
            ? newPickerVariants.map((att) => att._id)
            : newPickerAttributes
                  .filter((newAttr) => !pickerAttributes.find((attr) => attr._id === newAttr._id))
                  .map((addedAttr) => addedAttr._id)

    if (!deletedAttrIds.length && !addedAttrIds.length) {
        return {
            ...state,
            pickerVariants: newPickerVariants,
        }
    }

    const _design = JSON.parse(JSON.stringify(designs))
    let isNeedSelectedAttribute = false

    const updatedDesigns = []
    if (deletedAttrIds.length) {
        if (deletedAttrIds.includes(selectedAttribute._id)) isNeedSelectedAttribute = true

        const _updatedDesigns = _design
            .map((design) => {
                design.linkedAttrIds = design.linkedAttrIds.filter(
                    (linkedAttrId) => !deletedAttrIds.includes(linkedAttrId),
                )

                if (!design.linkedAttrIds.length) {
                    return null
                }

                const sidesChange = Object.keys(design.side)
                handleCleanUpCacheCapture(state, {sides: sidesChange, attributeIds: deletedAttrIds})

                return design
            })
            .filter(Boolean)

        updatedDesigns.push(..._updatedDesigns)

        for (const attribute of pickerAttributes) {
            if (!deletedAttrIds.includes(attribute._id)) continue

            const {ref} = attribute
            Object.keys(ref).forEach((side) => (ref[side] = null))
        }

        const hasDefault = updatedDesigns.find((d) => d.isDefault)
        if (!hasDefault && updatedDesigns.length) {
            updatedDesigns[0].isDefault = true
        }
    }

    if (addedAttrIds.length) {
        const _updatedDesigns = _design
            .map((design) => {
                if (design.isDefault) {
                    const filteredAddedVariants = addedAttrIds.filter(
                        (addedId) => !design.linkedAttrIds.includes(addedId),
                    )

                    const {safeZone} =
                        pickerAttributes.find((attr) => [...design.linkedAttrIds].includes(attr._id)) || {}
                    if (safeZone) {
                        const color = Object.values(safeZone)[0]?.fill

                        if (color) {
                            const linkedAttrs = newPickerAttributes.filter((attr) =>
                                filteredAddedVariants.includes(attr._id),
                            )
                            linkedAttrs.forEach((attr) => {
                                for (const background of Object.values(attr.safeZone)) {
                                    background.fill = color
                                }
                            })
                        }
                    }

                    design.linkedAttrIds.push(...filteredAddedVariants)

                    const sidesChange = Object.keys(design.side)
                    handleCleanUpCacheCapture(state, {sides: sidesChange, attributeIds: addedAttrIds})
                }

                return design
            })
            .filter(Boolean)

        updatedDesigns.push(..._updatedDesigns)

        for (const attribute of pickerAttributes) {
            if (!addedAttrIds.includes(attribute._id)) continue

            const {ref} = attribute
            Object.keys(ref).forEach((side) => (ref[side] = null))
        }

        const hasDefault = updatedDesigns.find((d) => d.isDefault)
        if (!hasDefault && updatedDesigns.length) {
            updatedDesigns[0].isDefault = true
        }
    }

    const newSelectedAttribute = isNeedSelectedAttribute ? newPickerAttributes[0] : selectedAttribute
    const mappedAttributes = genMappedAttributes(newPickerAttributes, newSelectedAttribute)
    const selectedSide = Object.keys(newSelectedAttribute.safeZone)[0]

    const logs = {
        selectedSide,
        mappedAttributes,
        designs: updatedDesigns,
        pickerVariants: newPickerVariants, // list variants has picked
        pickerAttributes: newPickerAttributes, // list attributes has picked
        selectedAttributeId: selectedAttribute._id,
    }

    updateHistory(history, logs)

    return {
        ...state,
        ...logs,
        mappedAttributes,
        selectedAttribute: newSelectedAttribute,
        selectedSide, // current side design selected
        designs: updatedDesigns,
        reloadPreview: true,
        history,
    }
}

const onCapture = async (stageData, options = {}) => {
    const {isArea, isPreview, isPublish, isSafeZone, scalePreviewRatio} = options
    const {ratioDefault, stageRef, side, x: bgX, y: bgY, width: bgWidth, height: bgHeight, mockup, cached} = stageData
    const stage = stageRef?.getStage()

    let artwork = null
    let area = null

    if (!stage) return {artwork, area}

    stage.scale({x: 1, y: 1})
    stage.position({x: 0, y: 0})
    stage.batchDraw()

    const handleCaptureArea = async (ratio = 1) => {
        const {preview_v2} = mockup
        const mimeType = 'jpeg'

        return new Promise((resolve) => {
            stage.toDataURL({
                pixelRatio: ratio,
                mimeType: 'image/' + mimeType,
                quality: 1,
                x: bgX,
                y: bgY,
                width: bgWidth,
                height: bgHeight,
                callback: function (canvas) {
                    const stageBlob = createBlob(convertBase64ToBlob(canvas, mimeType))
                    const {position, size} = preview_v2[side]
                    if (!stageBlob) resolve(null)

                    resolve({
                        side,
                        src: stageBlob,
                        ratioDefault,
                        x: position.left,
                        y: position.top,
                        width: size.width,
                        height: size.height,
                    })
                },
            })
        })
    }

    const handleCaptureArtwork = (cached, ratio = 1) => {
        if (!!cached) {
            return {side, src: cached}
        }

        const group = stage.children[0]
        const children = group?.getChildren()

        // bởi vì trong stage có 3 background nên cần check children.length > 3 thì được coi là có layer mới
        const hasChildren =
            children && (children.length > 3 || (children[1]?.attrs?.fill && children[1].attrs.fill !== '#ffffff'))

        if (!hasChildren) {
            return {side, src: null}
        }

        children.forEach((child, i) => {
            // hidden the last layer before capturing
            const {name} = child.attrs
            if (i === children.length - 1 || name === 'background') {
                child.visible(false)
            }
            if (i === 0) child.setAttr('fill', '')
            else child.globalCompositeOperation('source-over')
        })

        const mimeType = 'png'
        return new Promise((resolve) => {
            group.toDataURL({
                pixelRatio: ratio,
                mimeType: 'image/' + mimeType,
                quality: 1,
                x: bgX,
                y: bgY,
                width: bgWidth,
                height: bgHeight,
                callback: (canvas) => {
                    const groupBlob = createBlob(convertBase64ToBlob(canvas, mimeType))
                    if (groupBlob) resolve({side, src: groupBlob})

                    children.forEach((child, i) => {
                        // display the last layer after capturing
                        const {name} = child.attrs
                        if (i === children.length - 1 || name === 'background') {
                            child.visible(true)
                        }
                        if (i === 0) child.setAttr('fill', '#ffffff')
                        else child.globalCompositeOperation('source-atop')
                    })
                },
            })
        })
    }

    if (isPreview) artwork = await handleCaptureArtwork(cached.preview, scalePreviewRatio)
    else if (isPublish) artwork = await handleCaptureArtwork(cached.publish)

    if (isArea) area = await handleCaptureArea(scalePreviewRatio)
    if (isSafeZone) area = await handleCaptureArea()

    stage.scale({x: ratioDefault, y: ratioDefault})
    stage.position({x: 0, y: 0})
    stage.batchDraw()

    return {
        artwork,
        area,
    }
}

export const handleCaptureArtwork3D = (stageData) => {
    const { stageRef, safeZone } = stageData
    const {x: bgX, y: bgY, width: bgWidth, height: bgHeight} = safeZone

    const stage = stageRef?.getStage()
    const clonedStage = stage.clone()

    const group = clonedStage.children[0]
    const children = group.getChildren()

    clonedStage.scale({x: 1, y: 1})
    clonedStage.position({x: 0, y: 0})
    clonedStage.batchDraw()

    children.forEach((child, i) => {
        const {name} = child.attrs
        if (name === 'background' || name === 'transformer') {
            child.visible(false)
        }
        if (i === 0) child.setAttr('fill', '')
        else child.globalCompositeOperation('source-over')
    })

    const canvas = group.toCanvas({
        pixelRatio: 0.25,
        x: bgX,
        y: bgY,
        width: bgWidth,
        height: bgHeight,
    })

    clonedStage.destroy()

    return canvas
}

export const handleCaptures = async (attribute, options) => {
    console.log(`${PREFIX_LOG}[capture] start ================================`)
    console.time(`${PREFIX_LOG}[capture] end`)

    const captures = await Bluebird.mapSeries(Object.keys(attribute.ref), async (key) => {
        const stageRef = attribute.ref[key]
        const safeZone = attribute.safeZone[key]
        const mockup = attribute.mockup

        return onCapture({...safeZone, stageRef, side: key, mockup}, options)
    })

    console.timeEnd(`${PREFIX_LOG}[capture] end`)
    return captures
}

export const handleSaveCacheCapture = (state, data) => {
    const {pickerAttributes} = state
    const {attributes, artworks, type: typeCache} = data

    const attributeIds = attributes.map((attribute) => attribute._id)
    const cachedArtwork = artworks.reduce((acc, cache) => {
        if (!cache) return acc
        const {side, src} = cache
        acc[side] = src
        return acc
    }, {})

    for (const attribute of pickerAttributes) {
        if (!attributeIds.includes(attribute._id)) continue

        const {safeZone} = attribute

        for (const side in safeZone) {
            if (!cachedArtwork[side]) continue

            const value = safeZone[side]
            if (!value) continue

            value.cached = {
                ...value.cached,
                [typeCache]: cachedArtwork[side],
            }
        }

        attribute.isCached[typeCache] = true
    }

    return {...state}
}

/**
 * @param {object} state
 * @param {{attributes?: object[], sides: string[],attributeIds?: string[] }} data
 */
const handleCleanUpCacheCapture = (state, data) => {
    const {pickerAttributes} = state
    const {attributes, sides} = data

    const attributeIds = data.attributeIds || attributes.map((attribute) => attribute._id)

    for (const attribute of pickerAttributes) {
        if (!attributeIds.includes(attribute._id)) continue

        const {safeZone} = attribute

        for (const side in safeZone) {
            if (!sides.includes(side)) continue

            const value = safeZone[side]
            if (!value) continue

            value.cached = {
                preview: null,
                publish: null,
            }
        }

        attribute.isCached = {
            preview: false,
            publish: false,
        }
    }
}

export const handleCleanUpCache3D = (state, data) => {
    const {pickerAttributes} = state
    const {attributes, sides} = data

    const attributeIds = data.attributeIds || attributes.map((attribute) => attribute._id)

    for (const attribute of pickerAttributes) {
        if (!attributeIds.includes(attribute._id)) continue

        const {safeZone} = attribute

        for (const side in safeZone) {
            if (!sides.includes(side)) continue

            const value = safeZone[side]
            if (value?.cached?.preview3D) {
                value.cached.preview3D = null
            }
        }
    }
}

const getMockupConfig = (mockup) => {
    const {side_infos, preview_v2} = mockup

    const config = Object.entries(side_infos).reduce((acc, [key, value]) => {
        const {fusion_size} = value
        const lowerCaseKey = key.toLowerCase()

        acc.side = lowerCaseKey
        acc.width = fusion_size.artwork_fusion_size.width
        acc.height = fusion_size.artwork_fusion_size.height

        const partConfigs = {}
        for (const key in preview_v2) {
            const {position, size} = preview_v2[key]
            partConfigs[key] = {
                top: position.top,
                left: position.left,
                width: size.width,
                height: size.height,
            }
        }
        acc.part_configs = partConfigs

        return acc
    }, {})

    return config
}

/**
 * @param {object} variant
 * @param {object[]} artworks
 * @param {string} artworks.src
 * @param {string} artworks.side
 * @param {object} options
 * @param {number} options.scale
 * @param {number} options.ratio
 * @param {boolean} options.isGetDesigned
 */
export const handleGenerateDesign = async (mockup, artworks, options = {}) => {
    const {scale = 1, ratio = 1} = options
    const config = getMockupConfig(mockup)

    const areas = artworks
        .map((artwork) => {
            const {src, side} = artwork
            if (!src) return null

            const currentPart = config.part_configs[side]

            return {
                side,
                blobUrl: src,
                left: currentPart.left * ratio,
                top: currentPart.top * ratio,
            }
        })
        .filter(Boolean)

    /**
     * @type {Blob}
     */
    const design = await composite({
        images: areas,
        scale: scale,
        width: config.width * ratio,
        height: config.height * ratio,
    })

    // const blob = URL.createObjectURL(design)
    // downloadFile(blob, 'front.png')

    return {
        design,
        config,
    }
}

/**
 * @param {{design: Blob}} param
 */
export const handleUploadDesign = async ({design, config, presignedUrl}) => {
    // const {data} = await getLinksUploadArtwork({limit: 1})

    const fileReader = new FileReader()
    await new Promise((resolve, reject) => {
        fileReader.onload = function () {
            resolve()
        }
        fileReader.onerror = function () {
            reject(fileReader.error)
        }

        fileReader.readAsArrayBuffer(design)
    })

    const buffer = fileReader.result

    const response = await axios.put(presignedUrl, buffer, {
        headers: {
            'Content-Type': 'image/png',
        },
    })

    const designUrl = response.config.url?.split('?')[0]

    const payload = {
        resize_config: {
            width: config.width,
            height: config.height,
        },
        artworks: {
            front: designUrl,
        },
    }

    return uploadArtworkCaptureV2(payload)
}

const isValidHexColor = (colorCode) => {
    const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/
    return hexColorRegex.test(colorCode)
}

export const generateImagePreviewUrl = (host, object) => {
    const {files, endpoint_url} = Object.assign({}, host)
    if (!endpoint_url) return null

    const arrPaths = files.map((file) => file.path)
    const strPaths = arrPaths.join('-')

    const {listSku, mockup, attributes} = object
    const sku = listSku[0]

    // Filter support sides && selected attribute values has value type is label
    const mockupInfos = mockup.meta.mockup_infos.filter(({side}) => ALLOW_ARTWORK_SIDES.includes(side.toLowerCase()))

    const color = attributes.find((attr) => attr.type === 'color')?.value_code
    const hexColor = isValidHexColor(color) ? color : DEFAULT_MOCKUP_COLOR

    return mockupInfos.map((data) => {
        let side = data.side
        if (data.name) side = side + '-name:' + data.name

        return `${endpoint_url}/${sku}/${hexColor.substring(1)}/${strPaths}/${side}/regular.jpg`
    })
}

export const isArraysEqual = (attr1, attr2) => {
    if (attr1.length !== attr2.length) return false

    const sortedAttr1 = attr1.slice().sort()
    const sortedAttr2 = attr2.slice().sort()

    return sortedAttr1.every((item, index) => {
        const keys1 = Object.keys(item).sort()
        const keys2 = Object.keys(sortedAttr2[index]).sort()

        if (keys1.length !== keys2.length) return false

        return keys1.every((key, idx) => {
            return key === keys2[idx] && item[key] === sortedAttr2[index][key]
        })
    })
}

export const isSubsetAttributes = (attributes, arr) => {
    const sortedArr = _.sortBy(arr)
    return attributes.some((attrs) => _.isEqual(_.sortBy(attrs), sortedArr))
}

export const handleUpdateProductDetail = (state, payload) => {
    const {productDetail} = state
    const {field, value} = payload

    let detail = {...productDetail}
    switch (field) {
        case 'hide_from_listing_pages':
            detail.is_shop_hidden = value
            detail.is_collection_hidden = value
            break
        case 'facebook_pixel_id':
            detail.meta.facebook_pixel_id = value
            break
        case 'facebook_conversion':
            detail.meta.facebook_conversion = value
            break
        default:
            detail[field] = value
            break
    }
    return {
        ...state,
        productDetail: detail,
    }
}

export const formatMockupSideName = (side) => {
    if (!side) return ''
    if (side.includes('_')) {
        side = side.replace('_', ' ')
    }
    if (side.includes('-')) {
        side = side.replace('-', ' ')
    }
    return side.toUpperCase()
}

export const mergeAttributesByDesign = (attributes, designs) => {
    const linkedAttrIds = designs.find((item) => item.isDefault)?.linkedAttrIds

    const linkedAttrs = attributes.filter((attr) => linkedAttrIds.includes(attr._id))
    const unlinkedAttrs = attributes.filter((attr) => !linkedAttrIds.includes(attr._id))

    const sameMockupAttr = _.groupBy(linkedAttrs, 'mockup._id')

    const attrsData = Object.values(sameMockupAttr)

    for (const attrs of attrsData) {
        for (const attr of attrs) {
            attr.level = attrsData.length > 1 ? 'variant' : 'product'
        }
    }

    if (unlinkedAttrs.length) {
        unlinkedAttrs.forEach((unlinkedAttr) => {
            unlinkedAttr.level = 'variant'
            attrsData.push([unlinkedAttr])
        })
    }

    return attrsData
}

export const getCenterBox = (x, y, width, height, rotation) => {
    const angleRad = (rotation * Math.PI) / 180
    const cosA = Math.cos(angleRad)
    const sinA = Math.sin(angleRad)
    const wp = width / 2
    const hp = height / 2
    const px = x + wp * cosA - hp * sinA
    const py = y + wp * sinA + hp * cosA
    return {px: Math.round(px * 1000) / 1000, py: Math.round(py * 1000) / 1000}
}

export const determineActualTextPosition = (
    text,
    fontFamily,
    fontSize,
    align = 'left',
    rotation = 0,
    width = null,
    height = null,
    x = null,
    y = null,
    resizeCenter = true,
) => {
    if (x) x = Math.round(x * 1000) / 1000
    if (y) y = Math.round(y * 1000) / 1000
    const {cloneText, tr} = initVirtualTransformer({
        text,
        fontSize: fontSize ? Math.round(fontSize) : 24,
        align,
        fontFamily,
        draggable: false,
        x: x,
        y: y,
        rotation,
    })
    // Re get width and height from transformer
    let {width: newWidth, height: newHeight} = tr.getSize()
    newWidth = Math.round(newWidth * 1000) / 1000 + Math.round(fontSize / 2) // 1 word for hidden text
    newHeight = Math.round(newHeight * 1000) / 1000 + 0.1 // 0.1 for hidden text
    if (resizeCenter) {
        if (rotation === 0) {
            x += (width - newWidth) / 2
            y += (height - newHeight) / 2
        } else if (width && height) {
            const oldCenter = getCenterBox(x, y, width, height, rotation)
            const newCenter = getCenterBox(x, y, newWidth, newHeight, rotation)
            const {moveX, moveY} = {moveX: newCenter.px - oldCenter.px, moveY: newCenter.py - oldCenter.py}

            x -= moveX / 2
            y -= moveY / 2
        }

        cloneText.remove()
    }
    tr.destroy()
    return {
        width: newWidth,
        height: newHeight,
        x,
        y,
    }
}

export const loadCustomFont = (fontFamily, linkFont) => {
    if (!linkFont) {
        return Promise.resolve(fontFamily)
    }
    return new Promise((resolve, reject) => {
        const customFont = new FontFace(fontFamily, `url(${linkFont})`)
        customFont
            .load()
            .then((loaded_face) => {
                document.fonts.add(loaded_face)
                resolve(fontFamily)
            })
            .catch((error) => {
                console.error(error)
                reject(`Cann't found the font`)
            })
    })
}

export const capitalizeText = (text) => {
    if (!text) return
    text = text.toLowerCase()
    return text
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
}

export const drawTextAlongPath = async (textLayer, ratioDefault) => {
    if (!textLayer) return
    const {width, height, text, fontSize, fontFamily, fill, stroke} = textLayer
    const canvasElement = document.createElement('canvas')
    canvasElement.id = 'canvas'
    canvasElement.hidden = true
    canvasElement.width = width
    canvasElement.height = height
    document.body.appendChild(canvasElement)
    const canvas = document.getElementById('canvas')
    const ctx = canvas.getContext('2d')
    const rad = canvas.height * 0.4
    const centX = canvas.width / 2
    const centY = canvas.height / 2
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    ctx.font = fontSize * ratioDefault + `px ${fontFamily}`
    ctx.textAlign = 'center'
    ctx.textBaseline = 'bottom'
    ctx.fillStyle = fill
    ctx.strokeStyle = stroke

    ctx.textBaseline = 'middle'
    ctx.fillCircleText(text, centX, centY, rad, Math.PI * 1.5)

    const exportCanvasAsBlob = (canvas) => {
        return new Promise((resolve) => {
            canvas.toBlob(
                (blob) => {
                    if (blob) {
                        const imageSrc = URL.createObjectURL(blob)
                        resolve(imageSrc)
                    }
                },
                'image/png',
                0.0,
            )
        })
    }

    const arcedText = await exportCanvasAsBlob(canvas)
    console.log({arcedText})
    canvas.remove()
    return arcedText
}

export const getLowQualityImage = (src, quality = 0.5) => {
    return new Promise((resolve) => {
        const image = new window.Image()
        image.crossOrigin = 'Anonymous'
        image.src = src
        let dataURL
        image.onload = () => {
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')
            canvas.width = image.width
            canvas.height = image.height
            context.drawImage(image, 0, 0)
            dataURL = canvas.toDataURL('image/jpeg', quality)
            resolve(dataURL)
        }
    })
}

export const isMacOs = () => {
    return navigator.userAgent.indexOf('Mac OS X') !== -1
}

export const loadImage = (src) => {
    return new Promise((resolve, reject) => {
        const image = new Image()
        image.crossOrigin = 'Anonymous'
        image.src = src
        image.onload = () => resolve(image)
        image.onerror = () => reject(new Error(`Failed to load image: ${src}`))
    })
}

function curry(func) {
    return function curried(...args) {
        if (args.length >= func.length) {
            return func.apply(this, args)
        } else {
            return function (...args2) {
                return curried.apply(this, args.concat(args2))
            }
        }
    }
}

export const moveLayer = (direction, step, layer) => {
    const {x, y} = layer
    switch (direction) {
        case MOVE_LAYER_DIRECTION_KEY.UP:
            return {...layer, y: y - step}
        case MOVE_LAYER_DIRECTION_KEY.DOWN:
            return {...layer, y: y + step}
        case MOVE_LAYER_DIRECTION_KEY.LEFT:
            return {...layer, x: x - step}
        case MOVE_LAYER_DIRECTION_KEY.RIGHT:
            return {...layer, x: x + step}
        default:
            return layer
    }
}

export const curriedMoveLayer = curry(moveLayer)
