import React, {useState} from 'react'
import {ReactComponent as ChevronRight} from '../../../assets/icons/chevron-right.svg'
import {usePreviewArtworks} from '../../../hooks/usePreviewArtworks'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'
import {
    actionChangeDisplay,
    actionChangeSelectedLayer,
    actionChangeStatusPublish,
    actionUpdateVariantRetailPrice,
} from '../context/SelectedVariantContext/action'
import {getErrorRetailPrice} from '../context/SelectedVariantContext/helper'
import ModalPublish from './modalPublish/ModalPublish'
import {toaster} from '../../../shared/PFToast'
import Bluebird from 'bluebird'
import {saveTempImagesDesign, saveTempMockupDesign} from '../../../services/ProductLinesServices'
import {getLinksUploadArtwork, uploadTempArtwork} from '../../../services/ArtworkTemplateServices'
import {getCurrentStore} from '../../../services/CurrentStoreServices'
import {getDefaultBaseURL} from '../../../services/CreateStoreAPIService'
import {isBackofficeEnv} from '../../../helpers/isBackoffceEnv'
import {getLocalData} from '../../../services/StorageServices'
import {base64ToFile} from '../../../helpers/imageFormat'
import useUploadBackgroundPublish from '../../../hooks/useUploadBackgroundPublish'

const Publish = () => {
    const {
        state: {
            pickerAttributes,
            display,
            productDetail,
            isPublish,
            galleryImage,
            pickerVariants,
            runningImageCompress,
            currentBgImageBase64,
        },
        dispatch,
    } = useSelectedVariantContext()
    const {uploadBackground} = useUploadBackgroundPublish()

    const [personalizedArtworkPayloads, setPersonalizedArtworkPayloads] = useState(null)

    const {fetchDesigns} = usePreviewArtworks()

    const setShowModalPublish = (value) => {
        setPersonalizedArtworkPayloads(null)
        dispatch(actionChangeStatusPublish(value))
    }

    const _variantRetailPrice = () => {
        const validateVariants = pickerVariants.map((variant) => {
            return {
                ...variant,
                error: getErrorRetailPrice(variant.retail_price, variant.base_cost),
            }
        })
        return validateVariants
    }

    const _generateArtworkPayload = async (designs, presignedUrls, keyBackground) => {
        const storeId = getCurrentStore()
        const base_url = getDefaultBaseURL()
        const payload = await Bluebird.all(
            designs.map(async ({mockup, listSku, design, level}, index) => {
                const {meta, side_infos} = mockup
                const artwork_fusion_size = Object.values(side_infos)[0].fusion_size.artwork_fusion_size
                if (isBackofficeEnv()) {
                    return {
                        variant_skus: JSON.stringify(listSku),
                        mockup_infos: JSON.stringify(
                            meta.mockup_infos.map((item) => ({
                                name: item.name,
                                side: item.side,
                                background: keyBackground || undefined,
                            })),
                        ),
                        base_url,
                        site_id: storeId,
                        resize_config: JSON.stringify({
                            width: artwork_fusion_size.width,
                            height: artwork_fusion_size.height,
                        }),
                        front: design,
                        level,
                        keyBackground,
                    }
                }
                const presignedUrl = presignedUrls[index]
                await uploadTempArtwork(presignedUrl, design)

                return {
                    variant_skus: listSku,
                    mockup_infos: meta.mockup_infos.map((item) => ({
                        name: item.name,
                        side: item.side,
                    })),
                    resize_config: {
                        width: artwork_fusion_size.width,
                        height: artwork_fusion_size.height,
                    },
                    link: presignedUrl.split('?')[0],
                    level,
                    keyBackground,
                }
            }),
        )
        return payload
    }

    const _onClickPublish = async () => {
        try {
            if (display !== 'pricing') {
                let invalidDpi = null
                for (const attr of pickerAttributes) {
                    const {safeZone} = attr

                    for (const [key, value] of Object.entries(safeZone)) {
                        const totalInvalidDpi = value.invalidsDpi.length
                        if (totalInvalidDpi) {
                            invalidDpi = {
                                side: key,
                                total: totalInvalidDpi,
                            }
                            break
                        }
                    }
                }
                if (invalidDpi) {
                    return toaster({
                        type: 'error',
                        message: 'Invalid DPI',
                    })
                }
                dispatch(actionChangeSelectedLayer(null))
                return dispatch(actionChangeDisplay('pricing'))
            }

            const variantRetailPrice = _variantRetailPrice()
            const isInvalidVariant = variantRetailPrice.some((v) => v.error.length > 0)
            if (isInvalidVariant) {
                return dispatch(actionUpdateVariantRetailPrice(variantRetailPrice))
            }

            if (isBackofficeEnv()) {
                setShowModalPublish(true)
                await Bluebird.delay(1000)
            }

            let keyBackground = ''
            if (currentBgImageBase64) {
                const file = await base64ToFile(currentBgImageBase64, 'background-image.jpg', 'text/plain')
                keyBackground = await uploadBackground(file)
            }

            const designs = await fetchDesigns()
            const {data} = await getLinksUploadArtwork({limit: designs.length})

            const presignedUrls = data || []
            if (presignedUrls.length !== designs.length) throw new Error('Failed to get presigned url')

            const payload = await _generateArtworkPayload(designs, presignedUrls, keyBackground)

            if (isBackofficeEnv()) {
                setPersonalizedArtworkPayloads(payload)
            } else {
                const {attributes, collections} = productDetail
                const filteredAttributes = attributes.map((attribute) => {
                    const filteredValues = attribute.values.filter((value) =>
                        pickerVariants.some((variant) =>
                            variant.attributes.some(
                                (variantAttribute) =>
                                    variantAttribute.name === attribute.name &&
                                    variantAttribute.value_code === value.code,
                            ),
                        ),
                    )

                    return {...attribute, values: filteredValues}
                })
                const tempMockupPayload = {
                    artworks: payload,
                    product: {
                        ...productDetail,
                        collections: Array.isArray(collections)
                            ? collections.map((collection) => collection.value)
                            : [],
                        productLine: {
                            variants: pickerVariants,
                            attributes: filteredAttributes,
                        },
                    },
                    gallery_urls: [galleryImage],
                }
                delete tempMockupPayload.mockups

                const designLibrary = getLocalData('designLibrary') || []
                const tempData = designLibrary.map((item) => {
                    return {
                        name: item.name,
                        link: item.src,
                    }
                })

                const {
                    success: successImage,
                    data: dataImage,
                    message: messageImage,
                } = await saveTempImagesDesign(tempData)
                if (!successImage) throw new Error(messageImage)

                const {success, data, message} = await saveTempMockupDesign(tempMockupPayload)
                if (!success) throw new Error(message)
                const redirect = `${
                    process.env.REACT_APP_BASE_URL || 'https://seller-staging.merchize.com'
                }/mockup-design?id=${data}&createMockup=true&idLibraries=${dataImage}`
                window.open(redirect, '_self')
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <button
                type={'button'}
                className={'Design btn btn-primary flex-center'}
                disabled={runningImageCompress}
                onClick={_onClickPublish}
            >
                {display === 'pricing' ? 'Publish' : 'Next'} <ChevronRight className={'ms-1'} />
            </button>
            {/*<PFButton*/}
            {/*    type="button"*/}
            {/*    color="primary"*/}
            {/*    loading={loadingTemp}*/}
            {/*    onClick={}*/}
            {/*    className="Publish Design flex-center"*/}
            {/*    disabled={(disablePublish && display === 'pricing') || runningImageCompress || loadingTemp}*/}
            {/*    id={display === 'pricing' ? 'Publish' : 'Next'}*/}
            {/*    buttonText={*/}
            {/*        <>*/}
            {/*            {display === 'pricing' ? 'Publish' : 'Next'}*/}
            {/*            <ChevronRight width={7} height={10} className="Icon" />*/}
            {/*        </>*/}
            {/*    }*/}
            {/*/>*/}

            {isBackofficeEnv() && isPublish && (
                <ModalPublish
                    product={productDetail}
                    variants={pickerVariants}
                    galleryImage={galleryImage}
                    personalizedArtworkPayload={personalizedArtworkPayloads}
                    isOpen={isPublish}
                    onClose={() => setShowModalPublish(false)}
                />
            )}
        </>
    )
}

export default Publish
