import React from 'react'

const templateHelpers = [
    {
        areaName: 'Print Area',
        description: 'the FULL SIZE area to be printed',
        className: 'border border-solid border-gray-700',
    },
    {
        areaName: 'Breed Area',
        description: 'extends beyond the safe zone for size adjustments',
        className: 'bg-gray-400',
    },
    {
        areaName: 'Safe Print Area',
        description: 'keeps all important design elements inside',
        className: 'shadow-1',
    },
    {
        areaName: '3D Preview Area',
        description: 'the size for mockup preview',
        className: 'border-dashed border-success',
    },
]

const TemplateHelper = ({has3DPreview}) => {
    const templates = has3DPreview ? templateHelpers : templateHelpers.slice(0, 3)

    return (
        <div
            className={'TemplateHelper list text-base'}
            style={{
                '--gap': '0.25rem',
            }}
        >
            {templates.map(({className, description, areaName}) => {
                return (
                    <div
                        className={'TemplateHelper__Item d-flex align-items-center justify-content-start list'}
                        style={{
                            '--gap': '0.75rem',
                            '--direction': 'row',
                        }}
                    >
                        <div
                            className={className}
                            style={{
                                width: '24px',
                                height: '24px',
                            }}
                        ></div>
                        <div className={'text-start'}>
                            <span className={'text-center font-bold'}>{areaName}: </span>
                            <span className={'text-center'}>{description}</span>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default TemplateHelper
