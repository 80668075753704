import classNames from 'classnames'

const withContainer = (WrappedComponent) => {
    return (props) => {
        const {className} = props
        return (
            <div className={classNames('Container shadow-2', className)}>
                <WrappedComponent {...props} />
            </div>
        )
    }
}

export default withContainer
