import {
    ADD_IMAGE_LAYER,
    ADD_TEXT_LAYER,
    SAVE_CACHE_CAPTURE,
    CHANGE_ACTIVE_SIDE,
    CHANGE_BACKGROUND_COLOR,
    CHANGE_BACKGROUND_IMAGES,
    CHANGE_BACKGROUND_IMAGES_TYPE,
    CHANGE_TAB_DESIGN,
    CHANGE_BACKGROUND_IMAGES_REF,
    CHANGE_LOADING_BACKGROUND,
    UPDATE_LIST_BACKGROUND_IMAGES,
    CHANGE_LINKED_VARIANT,
    CHANGE_SELECTED_LAYER,
    CHANGE_STAGE_DRAG,
    CHANGE_STATUS_PUBLISH,
    CREATE_STAGE_REF,
    DUPLICATE_LAYER,
    INIT_APPLIED_VARIANTS,
    APPLIED_RESIZE_ATTRIBUTES,
    INIT_PRODUCT_DATA,
    MOVE_LAYERS,
    REDO_HISTORY,
    REMOVE_SELECTED_LAYER,
    RERENDER_STATE,
    SET_CAMPAIGN_DETAIL,
    UNDO_HISTORY,
    UPDATE_GALLERY_IMAGE,
    UPDATE_LAYER_ATTRIBUTE,
    UPDATE_PICKER_VARIANTS,
    UPDATE_PREVIEW_IMAGES,
    UPDATE_SCREEN_DISPLAY,
    UPDATE_SELECTED_ATTRIBUTE,
    UPDATE_SIZE_EDITOR_WRAPPER,
    UPDATE_VARIANT_RETAIL_PRICE,
    SET_RUNNING_IMAGE_COMPRESS,
    SET_IMAGE_COMPRESS_PROGRESS,
    SET_IS_ADMIN,
    COPY_LAYER,
    PASTE_LAYER,
    UPDATE_APPLY_ALL_PLACEMENTS,
    UPDATE_IMAGE_LAYER_LOADING,
    UPDATE_FONTS,
    UPDATE_PREVIEW_IMAGES_3D,
    REDUX_DEVTOOL_JUMP,
} from './constants'

export const actionUndo = () => ({
    type: UNDO_HISTORY,
})

export const actionRedo = () => ({
    type: REDO_HISTORY,
})

/**
 * @param {Boolean|undefined} isDrag
 * @returns
 */
export const actionChangeStageDrag = (isDrag) => ({
    type: CHANGE_STAGE_DRAG,
    payload: isDrag,
})

export const actionChangeStatusPublish = (status) => ({
    type: CHANGE_STATUS_PUBLISH,
    payload: status,
})

/**
 * @param {object} payload
 * @param {number} payload.width
 * @param {number} payload.height
 */
export const actionUpdateSizeEditorWrapper = (payload) => ({
    type: UPDATE_SIZE_EDITOR_WRAPPER,
    payload,
})

/**
 * @param {'design'|'preview'|'pricing'} screen
 */
export const actionChangeDisplay = (screen) => ({
    type: UPDATE_SCREEN_DISPLAY,
    payload: screen,
})

export const actionInitProduct = (payload) => ({
    type: INIT_PRODUCT_DATA,
    payload,
})

/**
 * @param {object[]} variants
 */
export const actionInitAppliedVariants = () => ({
    type: INIT_APPLIED_VARIANTS,
})

export const actionResizeAttributes = () => ({
    type: APPLIED_RESIZE_ATTRIBUTES,
})

export const actionUpdateVariantRetailPrice = (variants) => ({
    type: UPDATE_VARIANT_RETAIL_PRICE,
    payload: variants,
})

/**
 * @param {DOM} stageRef
 * @returns
 */
export const actionCreateStageRefBySide = ({stageRef, side, attributeIds}) => ({
    type: CREATE_STAGE_REF,
    payload: {stageRef, side, attributeIds},
})

export const updateActiveSide = (selectedSide) => ({
    type: CHANGE_ACTIVE_SIDE,
    payload: selectedSide,
})

export const actionAddTextLayer = () => ({
    type: ADD_TEXT_LAYER,
})

/**
 * add image in to current stage side
 * @param {ImageObject} imageObj
 * @returns
 */
export const actionAddImageLayer = (imageObj) => ({
    type: ADD_IMAGE_LAYER,
    payload: imageObj,
})

export const actionChangeSelectedLayer = (layer = null) => ({
    type: CHANGE_SELECTED_LAYER,
    payload: layer,
})

export const updateLayerAttribute = (layer = {}, updateHistory = true) => {
    return {
        type: UPDATE_LAYER_ATTRIBUTE,
        payload: {layer, updateHistory},
    }
}

export const removeLayer = (layer = {}) => ({
    type: REMOVE_SELECTED_LAYER,
    payload: layer,
})

/**
 *
 * @param {object} currLayer
 * @param {object|undefined} options
 * @param {boolean} options.scopeAll
 * @returns
 */
export const actionDuplicateLayer = (currLayer = {}, options = {}) => ({
    type: DUPLICATE_LAYER,
    payload: {
        currLayer,
        options,
    },
})

export const actionMoveLayers = (sortedLayers) => ({
    type: MOVE_LAYERS,
    payload: sortedLayers,
})

export const actionUpdatePickerVariants = (variantIds) => ({
    type: UPDATE_PICKER_VARIANTS,
    payload: variantIds,
})

/**
 * @param {{key: string, data: object}} selected
 */
export const actionChangeSelectedAttribute = (selected) => ({
    type: UPDATE_SELECTED_ATTRIBUTE,
    payload: selected,
})

export const actionChangeLinkedVariant = (linked) => ({
    type: CHANGE_LINKED_VARIANT,
    payload: linked,
})

export const actionUpdatePreviewImages = (previewImages) => ({
    type: UPDATE_PREVIEW_IMAGES,
    payload: previewImages,
})

export const actionUpdatePreviewImages3D = (previewImages) => ({
    type: UPDATE_PREVIEW_IMAGES_3D,
    payload: previewImages,
})

export const actionUpdateBgColor = (colorCode) => ({
    type: CHANGE_BACKGROUND_COLOR,
    payload: colorCode,
})

export const actionUpdateBgImage = (bgImage) => ({
    type: CHANGE_BACKGROUND_IMAGES,
    payload: bgImage,
})

export const actionUpdateBgImageType = (type) => ({
    type: CHANGE_BACKGROUND_IMAGES_TYPE,
    payload: type,
})

export const actionChangeTabDesign = (type) => ({
    type: CHANGE_TAB_DESIGN,
    payload: type,
})

export const actionUpdateBgImageRef = (ref) => ({
    type: CHANGE_BACKGROUND_IMAGES_REF,
    payload: ref,
})

export const actionUpdateLoadingBackground = (isLoading) => {
    return {
        type: CHANGE_LOADING_BACKGROUND,
        payload: isLoading,
    }
}

export const actionUpdateListBbImages = (bgImages) => ({
    type: UPDATE_LIST_BACKGROUND_IMAGES,
    payload: bgImages,
})

export const actionRerenderState = () => ({
    type: RERENDER_STATE,
})

export const actionUpdateGalleryImage = (image) => ({
    type: UPDATE_GALLERY_IMAGE,
    payload: image,
})

export const actionSetCampaignDetail = (product) => ({
    type: SET_CAMPAIGN_DETAIL,
    payload: product,
})

export const actionSaveCacheCapture = (data) => ({
    type: SAVE_CACHE_CAPTURE,
    payload: data,
})

export const actionSetRunningImageCompress = (running) => ({
    type: SET_RUNNING_IMAGE_COMPRESS,
    payload: running,
})

export const actionSetImageCompressProgress = (progress) => ({
    type: SET_IMAGE_COMPRESS_PROGRESS,
    payload: progress,
})

export const actionSetIsAdmin = (isAdmin) => ({
    type: SET_IS_ADMIN,
    payload: isAdmin,
})

export const actionCopyLayer = (layer = null) => ({
    type: COPY_LAYER,
    payload: layer,
})

export const actionPasteLayer = () => ({
    type: PASTE_LAYER,
})

export const actionChangeApplyAllPlacements = (value) => ({
    type: UPDATE_APPLY_ALL_PLACEMENTS,
    payload: value,
})

export const actionUpdateImageLayerLoading = (loading = false) => ({
    type: UPDATE_IMAGE_LAYER_LOADING,
    payload: loading,
})

export const actionReduxDevtoolJump = (jumpState = '') => ({
    type: REDUX_DEVTOOL_JUMP,
    payload: JSON.parse(jumpState),
})


export const updateFonts = (fonts) => {
    return {
        type: UPDATE_FONTS,
        payload: fonts,
    }
}
