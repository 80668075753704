export class BabylonLoading {
    displayLoadingUI() {
        const canvasContainer = document.getElementById('preview_live_artwork_canvas')
        const spinner = document.createElement('div')
        spinner.className = 'spinner-border'
        canvasContainer.appendChild(spinner)
    }

    hideLoadingUI() {
        const canvasContainer = document.getElementById('preview_live_artwork_canvas')
        const spinner = canvasContainer && canvasContainer.querySelector('.spinner-border')
        if (spinner) {
            canvasContainer.removeChild(canvasContainer.querySelector('.spinner-border'))
        }
    }
}
