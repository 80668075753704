import React from 'react'
import {Progress} from 'reactstrap'
import {ReactComponent as HourGlass} from '../../../../../assets/icons/hourglass.svg'

const CampaignDesignLoading = ({text = 'Your file is uploading, please wait...', percentage = 0}) => {

    return (
        <div className="LoadingContainer text-center">
            <HourGlass width={60} height={60} />
            <div className="Percentage mt-3">
                <span className="text-primary">{percentage}%</span>
                <Progress className="my-3" value={percentage} />
            </div>
            <div className="LoadingText fs-14">{text}</div>
        </div>
    )
}

export default CampaignDesignLoading

