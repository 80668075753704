import React from 'react'
import TabsContainer from './tabs/TabsContainer'

const UploadContainer = () => {
    return (
        <div className={`UploadContainer NewVersion${'Front' === 'All' ? ' AllAreaTab' : ''}`}>
            <TabsContainer />
        </div>
    )
}

export default UploadContainer
