/**
 * @typedef {Record<string, {parts:[{x: number, y: number, width: number, height: number}]}>} UVConfigs
 */

import {
    ActionManager,
    ArcRotateCamera,
    Camera,
    Color3,
    Color4,
    DynamicTexture,
    Engine,
    ExecuteCodeAction,
    HemisphericLight,
    HighlightLayer,
    PassPostProcess,
    Scene,
    SceneLoader,
    Texture,
    Vector3
} from '@babylonjs/core'
import { Tools } from "@babylonjs/core/Misc/tools"
import '@babylonjs/loaders'
import { BabylonLoading } from './BabylonLoading'

class Babylon {
    /**
     * 
     * @param {HTMLCanvasElement} renderCanvas
     * @param {UVConfigs} uvConfigs
    */
   constructor(renderCanvas, configs) {
        this.DESIGN_BACKGROUND = '#ffffff'
        this.DEFAULT_CAMERA_RADIUS = 6
        this.TEXTURE_SIZE = configs.texture.size
        this.MATERIAL_CONFIGS = configs.material

        /** @type {UVConfigs} */
        this.uvConfigs = configs['uv_map_configs']

        /** @type {HTMLCanvasElement} */
        this.designCanvas = new OffscreenCanvas(this.TEXTURE_SIZE, this.TEXTURE_SIZE)
        this.renderCanvas = renderCanvas

        // engine
        const workCanvas = document.createElement('canvas')
        this.engine = new Engine(workCanvas, true, {
            preserveDrawingBuffer: false,
            antialias: true,
            stencil: true,
        })
        this.engine.setHardwareScalingLevel(1 / window.devicePixelRatio)
        this.engine.inputElement = this.renderCanvas
        
        this.engine.loadingScreen = new BabylonLoading()
        this.engine.displayLoadingUI()
        
        // scene
        this.scene = new Scene(this.engine)
        this.scene.useRightHandedSystem = true
        this.scene.clearColor = new Color4(0, 0, 0, 0)

        // main camera
        this.mainCamera = new ArcRotateCamera('MainCamera', Math.PI/2, Math.PI/2, configs.camera.radius, Vector3.Zero(), this.scene)
        this.mainCamera.lowerRadiusLimit = configs.camera.radius_min
        this.mainCamera.upperRadiusLimit = configs.camera.radius_max
        this.mainCamera.wheelPrecision = configs.camera.wheel_precision
        this.mainCamera.attachControl(true, false, false)
        this.mainCamera.inputs.attached.pointers.buttons = [0, 2]

        this.engine.registerView(this.renderCanvas, this.mainCamera, true)

        // highlight layer
        this.highlightLayer = new HighlightLayer('HighlightLayer', this.scene, {
            camera: this.mainCamera,
            isStroke: false,
            mainTextureRatio: 2,
        })

        // design texture
        this.designTexture = new DynamicTexture('DesignTexture', this.designCanvas, {
            scene: this.scene,
            samplingMode: Texture.NEAREST_LINEAR,
        })

        const light1 = new HemisphericLight('Light1', new Vector3(0, 0, 1), this.scene)
        light1.intensity = 0.3
        light1.groundColor = new Color3(0.3, 0.3, 0.3)
        const light2 = new HemisphericLight('Light2', new Vector3(0, 0, -1), this.scene)
        light2.intensity = 0.3
        light2.groundColor = new Color3(0.3, 0.3, 0.3)
        const light3 = new HemisphericLight('Light3', new Vector3(1, 0, 0), this.scene)
        light3.intensity = 0.3
        light3.groundColor = new Color3(0.3, 0.3, 0.3)
        const light4 = new HemisphericLight('Light4', new Vector3(0, 1, 0), this.scene)
        light4.intensity = 0.3
        light4.groundColor = new Color3(0.3, 0.3, 0.3)

        this.clearDesignCanvas()
    }

    attachMockupCamera(canvasId, positions) {
        this.mockupCameraPositions = positions

        const {
            horizontal_rotation = Math.PI / 2,
            vertical_rotation = Math.PI / 2,
            radius = this.DEFAULT_CAMERA_RADIUS,
        } = positions[0]
        this.mockupCamera = new ArcRotateCamera(
            'MockupCamera',
            horizontal_rotation, vertical_rotation, radius,
            Vector3.Zero(),
            this.scene,
        )
        const canvas = document.getElementById(canvasId)
        this.engine.registerView(canvas, this.mockupCamera, true)
    }

    updateMockupCameraPosition(index, screenshotCamera) {
        const camera = screenshotCamera || this.mockupCamera

        const {
            horizontal_rotation = Math.PI / 2,
            vertical_rotation = Math.PI / 2,
            radius = this.DEFAULT_CAMERA_RADIUS,
        } = this.mockupCameraPositions[index]
        const x = radius * Math.sin(horizontal_rotation) * Math.cos(vertical_rotation)
        const y = radius * Math.cos(horizontal_rotation)
        const z = radius * Math.sin(horizontal_rotation) * Math.sin(vertical_rotation)

        camera.position = new Vector3(x, y, z)
    }

    run() {
        this.engine.onResizeObservable.add(() => {
            const ratio = this.engine.getRenderWidth() / this.engine.getRenderHeight()
            this.mainCamera.fovMode = ratio > 1.0 ? Camera.FOVMODE_VERTICAL_FIXED : Camera.FOVMODE_HORIZONTAL_FIXED
            this.mockupCamera.fovMode = ratio > 1.0 ? Camera.FOVMODE_VERTICAL_FIXED : Camera.FOVMODE_HORIZONTAL_FIXED
        })

        this.engine.runRenderLoop(() => {
            if (this.scene.activeCamera) {
                this.scene.render()
            }
        })

        const container = document.getElementById('preview_live_artwork_container')
        this.resizeObserver = new ResizeObserver(() => {
            this.engine.resize()
        })
        this.resizeObserver.observe(container)

        this.engine.hideLoadingUI()
    }

    destroy() {
        this.engine.dispose()
        this.resizeObserver.disconnect()
    }

    async loadMaterialTexture(materialUrl, configs) {
        this.bumpTexture = new Texture(materialUrl)
        this.bumpTexture.level = configs.bump_level || 3
        this.bumpTexture.uScale = configs.bump_uScale || 5
        this.bumpTexture.vScale = configs.bump_vScale || 5
    }

    async loadModel(modelUrl, materialId) {
        const result = await SceneLoader.ImportMeshAsync(undefined, modelUrl, undefined, this.scene)

        const material = this.scene.getMaterialById(materialId, false)
        if (material) {
            material.albedoTexture = this.designTexture
            material.bumpTexture = this.bumpTexture

            Object.keys(this.MATERIAL_CONFIGS).forEach((key) => material[key] = this.MATERIAL_CONFIGS[key])
        }

        result.meshes.forEach((mesh) => {
            if (!mesh) return
            
            mesh.actionManager = new ActionManager(this.scene)
            mesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, (_ev) => {
                this.highlightLayer.addMesh(mesh, Color3.FromHexString('#007bff'))
            }))
            mesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, (_ev) => {
                this.highlightLayer.removeMesh(mesh)
            }))
        })
    }

    updateDesignTexture() {
        this.designTexture.update()
    }

    clearDesignCanvas(x = 0, y = 0, w = this.TEXTURE_SIZE, h = this.TEXTURE_SIZE) {
        const ctx = this.designCanvas.getContext('2d')
        ctx.fillStyle = this.DESIGN_BACKGROUND
        ctx.fillRect(x, y, w, h)
    }

    drawImage(image, x, y, w, h) {
        const ctx = this.designCanvas.getContext('2d')
        ctx.setTransform(1, 0, 0, -1, 0, this.TEXTURE_SIZE)
        this.clearDesignCanvas(x, y, w, h)
        ctx.drawImage(image, x, y, w, h)
        ctx.setTransform(1, 0, 0, 1, 0, this.TEXTURE_SIZE)
    }

    drawUV(image, side, index) {
        const start = Date.now()
        const uvConfig = this.uvConfigs[side].parts[index]
        this.drawImage(image, uvConfig.x, uvConfig.y, uvConfig.width, uvConfig.height)
        this.updateDesignTexture()
        console.debug(`[Babylon] drawUV | ${Date.now() - start} ms`, {image, side, index})
    }

    captureMockupCameraImages() {
        const camera = this.mockupCamera.clone()

        const ppp = new PassPostProcess('fxaa', 1, camera)
        ppp.samples = this.engine.getCaps().maxMSAASamples

        return new Promise(async (resolve) => {
            const result = []
            for (let i = 0; i < this.mockupCameraPositions.length; i++) {
                this.updateMockupCameraPosition(i, camera)
                const data = await this.captureCameraViewImage(camera)
                result.push(data)
            }
            ppp.dispose()
            camera.dispose()
            return resolve(result)
        })
    }

    captureCameraViewImage(camera) {
        return new Promise((resolve) => {
            Tools.CreateScreenshotUsingRenderTarget(
                this.engine,
                camera,
                { width: 1200, height: 1200 },
                (data) => {
                    return resolve(data)
                },
            )
        })
    }
}

let babylon = null

/**
 * @param {HTMLCanvasElement} [renderCanvas]
 * @param {} [configs]
 * @returns {Babylon}
 */
export const getBabylonInstance = (renderCanvas, configs) => {
    if (babylon) return babylon

    if (renderCanvas && configs) {
        babylon = new Babylon(renderCanvas, configs)
        return babylon
    }

    throw new Error('BabylonJS is not initialized')
}

export const destroyBabylonInstance = () => {
    if (babylon) {
        babylon.destroy()
        babylon = null
    }
}
