import React, {useState} from 'react'
import {ReactComponent as Shirt} from '../../assets/icons/shirt.svg'
import {ReactComponent as Upload} from '../../assets/icons/upload.svg'
import {ReactComponent as TextFields} from '../../assets/icons/text_fields.svg'
import {ReactComponent as Question} from '../../assets/icons/question.svg'
import {ReactComponent as ColorLens} from '../../assets/icons/color_lens.svg'
import {ReactComponent as Layers} from '../../assets/icons/all-layers.svg'
import MockupGuideline from './artwork-editor/MockupGuideline'
import {useSelectedVariantContext} from './context/SelectedVariantContext'
import {
    actionAddTextLayer,
    actionChangeSelectedLayer,
    actionChangeTabDesign,
} from './context/SelectedVariantContext/action'
import DesignLibraryModal from './artwork-editor/design-library/DesignLibraryModal'
import {useEditorLayers} from '../../hooks/useEditorLayers'
import useUploadFile from '../../hooks/useUploadFile'

const Toolbar = () => {
    const {
        state: {tabDesign, selectedAttribute, selectedSide, runningImageCompress},
        dispatch,
    } = useSelectedVariantContext()
    const {pending} = useUploadFile()
    const layers = useEditorLayers()
    const background = selectedAttribute?.safeZone[selectedSide]
    const [isOpenImageDesign, setIsOpenImageDesign] = useState(false)
    const [openGuideline, setOpenGuideline] = useState(false)
    const toggleGuideline = () => setOpenGuideline(!openGuideline)

    const setTabDesign = (type) => {
        if (type === 'background') {
            if (background) {
                dispatch(actionChangeSelectedLayer(null))
                dispatch(actionChangeTabDesign(type))
            }
        } else {
            if (type !== 'layer') dispatch(actionChangeSelectedLayer(null))
            dispatch(actionChangeTabDesign(type))
        }
    }

    const _toggle = () => {
        setIsOpenImageDesign((prev) => !prev)
    }

    const onAddText = () => {
        if (background) {
            dispatch(actionAddTextLayer())
        }
    }

    return (
        <>
            <ul>
                <li
                    className={`text-center ${pending || runningImageCompress ? 'disabled' : ''} ${tabDesign === 'product' ? 'active' : ''}`}
                    id="tab_product_v3"
                    onClick={() => tabDesign === 'product' ? setTabDesign('') : setTabDesign('product')}
                >
                    <div className="Icon">
                        <Shirt />
                    </div>
                    <label>Product</label>
                </li>
                <li
                    className={`text-center ${(background && !(pending || runningImageCompress)) ? '' : 'disabled'} ${tabDesign === 'addImage' ? 'active' : ''}`}
                    id="tab_add_image_v3"
                    onClick={() => {
                        if (background) {
                            _toggle()
                        }
                    }}
                >
                    <div className="Icon">
                        <Upload />
                    </div>
                    <label>Add image</label>
                </li>
                <li id="tab_add_text_v3" className={`text-center ${(background && !(pending || runningImageCompress)) ? '' : 'disabled'} ${tabDesign === 'addText' ? 'active' : ''}`} onClick={onAddText}>
                    <div className="Icon">
                        <TextFields />
                    </div>
                    <label>Add text</label>
                </li>
                <li id="tab_all_layers_v3" className={`text-center ${(layers.length === 0 || (pending || runningImageCompress)) ? 'disabled' : ''} ${tabDesign === 'layer' ? 'active' : ''}`}
                    onClick={() => tabDesign === 'layer' ? setTabDesign('') : setTabDesign('layer')}>
                    <div className="Icon">
                        <Layers />
                    </div>
                    <label>Layers</label>
                </li>
                <li
                    id="tab_background_color_v3"
                    className={`text-center ${(background && !(pending || runningImageCompress)) ? '' : 'disabled'} ${tabDesign === 'background' ? 'active' : ''}`}
                    onClick={() => tabDesign === 'background' ? setTabDesign('') : setTabDesign('background')}
                >
                    <div className="Icon">
                        <ColorLens />
                    </div>
                    <label>Background color</label>
                </li>
                <li id="tab_guideline_v3" className={`text-center ${pending || runningImageCompress ? 'disabled' : ''}`} onClick={toggleGuideline}>
                    <div className="Icon">
                        <Question />
                    </div>
                    <label>Guideline</label>
                    <MockupGuideline isOpen={openGuideline} toggle={toggleGuideline} />
                </li>
            </ul>
            {isOpenImageDesign && <DesignLibraryModal isOpen={isOpenImageDesign} toggle={_toggle} />}
        </>
    )
}

export default Toolbar