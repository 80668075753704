import React from 'react'
import UploadByAssets from './UploadByAssets'
import UploadByComputer from './UploadByComputer'

const TabUploadType = (props) => {
    return (
        <div className="Tabs">
            <div className="TabContent">
                <UploadByComputer {...props} />
            </div>
            <div className="TabContent pt-0">
                <UploadByAssets {...props} />
            </div>
        </div>
    )
}

export default TabUploadType

