import React, {Fragment, memo, useCallback, useEffect, useRef, useState} from 'react'
import {Rect, Text, Transformer} from 'react-konva'
import {
    drawTextAlongPath,
    hideToolbar,
    initTextActualSize,
    updateToolbarStyle,
} from '../../context/SelectedVariantContext/helper'

const MIN_TRANSFORM_WIDTH = 50
const MIN_TRANSFORM_HEIGHT = 20

/**
 *
 * @param {*} param
 * @returns
 */
const TextLayer = memo((props) => {
    const {textProps, isSelected, onSelect, onChange, zIndexDefault, background} = props

    const [image, setImage] = useState(null)
    const {hasArc, isLock} = textProps
    const textRef = useRef()
    const trRef = useRef()

    useEffect(() => {
        const layer = textRef.current
        layer.on('mouseenter mouseup dragend', () => {
            layer.getStage().container().style.cursor = 'grab'
        })
        layer.on('mouseleave', () => {
            layer.getStage().container().style.cursor = 'default'
        })
        layer.on('mousedown', () => {
            layer.getStage().container().style.cursor = 'grabbing'
        })

        return () => {
            layer.off('mouseenter')
            layer.off('mouseup')
            layer.off('dragend')
            layer.off('mouseleave')
            layer.off('mousedown')
        }
    }, [])

    const onShowToolbar = useCallback(() => {
        const trNode = trRef.current
        if (trNode && textProps) {
            const rotateIcon = trNode.findOne('.rotater')
            const pos = rotateIcon.getAbsolutePosition()
            const {rotation} = textProps
            const rect = textRef.current?.getClientRect()
            updateToolbarStyle({...pos, height: rect.height / 2, rotation}, 'text-toolbar')
        }
    }, [textRef, textProps])

    useEffect(() => {
        const textNode = textRef.current
        const trNode = trRef.current
        if (textNode && trNode) {
            if (isSelected) {
                trNode.nodes([textNode])
                trNode.moveToTop()
                trNode.getLayer().batchDraw()
                !isLock && onShowToolbar()
            } else {
                textNode.zIndex(zIndexDefault)
                onHideToolbar()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelected, isLock, zIndexDefault, textProps])

    const _drawTextAlongPath = useCallback(async () => {
        if (textProps.hasArc) {
            // setLoading(true)
            // dispatch(actionUpdateImageLayerLoading(true))
            const textBlob = await drawTextAlongPath(textProps, background.ratioDefault)
            const img = new window.Image()
            img.crossOrigin = 'Anonymous'
            img.onload = () => {
                const timer = setTimeout(() => {
                    setImage(img)
                    // setLoading(false)
                    // dispatch(actionUpdateImageLayerLoading(false))
                }, 1000)
                return () => {
                    clearTimeout(timer)
                }
            }
            img.onerror = () => {
                // setLoading(false)
                // toaster({type: 'error', message: 'Error loading image', duration: 6000})
            }
            img.src = textBlob
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textProps.hasArc, background])

    useEffect(() => {
        _drawTextAlongPath()
    }, [_drawTextAlongPath])

    useEffect(() => {
        setTimeout(() => {
            const {width, height} = initTextActualSize(textProps)
            onChange({...textProps, width, height}, false) // this onChange not update history
        }, 100)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textProps.fontFamily])

    const onDoubleClick = (event) => {
        const textNode = event.target
        if (!textNode || !trRef.current) return
        if (textNode.attrs.isLock) return

        //hide textNode and transformer
        textNode.hide()
        trRef.current.hide()

        const ratio = textNode.scale().x * background.ratioDefault

        const createTextarea = () => {
            const textarea = document.createElement('textarea')
            textarea.id = 'InlineEditor'
            textarea.className = 'InlineEditor'
            textarea.style.position = 'absolute'
            textarea.maxLength = 50

            // Lấy stage container (DOM element của canvas)
            const stage = textNode.getStage()
            const container = stage.container().getElementsByTagName('div')[0]

            // Dùng getClientRect() với option relativeTo để lấy bounding box của textNode
            // trong hệ quy chiếu của container
            // const textRect = textNode.getClientRect({relativeTo: container})

            const textRect = textNode.getClientRect({relativeTo: textNode.getStage().container()})

            // Nếu bạn cần điều chỉnh thêm (ví dụ: đẩy lên 20px), trừ luôn vào toạ độ y
            const correctionX = textNode.width() * 3 * ratio
            textarea.style.left = textRect.x + 'px'
            textarea.style.top = textRect.y + 'px'

            // Gán kích thước từ bounding box
            textarea.style.width = textRect.width + 'px'
            textarea.style.height = textRect.height + 'px'

            // Font-size của TextNode chưa được chuyển đổi theo scale hiển thị trên Stage,
            // nên nếu Stage được scale theo background.ratioDefault thì cần nhân thêm
            // tỷ lệ đó (cùng với scale riêng của textNode nếu có)
            const effectiveRatio = textNode.scale().x * background.ratioDefault
            textarea.style.fontSize = textNode.fontSize() * effectiveRatio + 'px'
            textarea.style.lineHeight = textNode.lineHeight()
            textarea.style.fontFamily = textNode.fontFamily()
            textarea.style.textAlign = textProps.align || 'left'
            textarea.style.color = textNode.fill()
            textarea.style.whiteSpace = 'nowrap'
            textarea.style.maxWidth = background.width + 'px'

            textarea.value = textNode.text()

            // Nếu TextNode có rotation, bạn có thể (nếu cần) xoay textarea cho khớp
            const rotation = textNode.rotation()
            if (rotation) {
                textarea.style.transformOrigin = 'left top'
                textarea.style.transform = `rotateZ(${rotation}deg)`
            }

            // Chèn textarea vào container của Stage (đảm bảo cùng hệ quy chiếu)
            container.appendChild(textarea)
            return textarea
        }

        const textarea = createTextarea()
        textarea.style.whiteSpace = 'nowrap'
        textarea.style.maxWidth = background.width + 'px'
        const rotation = textNode.rotation()
        let transform = ''
        if (rotation) {
            transform += 'rotateZ(' + rotation + 'deg)'
        }

        let px = 0
        var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
        if (isFirefox) {
            px += 2 + Math.round(textNode.fontSize() / 20)
        }
        transform += 'translateY(-' + px + 'px)'
        textarea.style.transform = transform

        // reset height
        textarea.style.height = 'auto'
        textarea.style.height = textarea.scrollHeight + 3 + 'px'
        textarea.focus()
        function removeTextarea() {
            if (!textarea || !textarea.parentNode) {
                return
            }
            window.removeEventListener('click', handleOutsideClick)
            textNode.show()
            if (trRef.current) {
                trRef.current.show()
                trRef.current.forceUpdate()
            }
            const {width, height} = initTextActualSize(textNode.attrs)
            const newX = textProps.x + (width - textProps.width) / 2
            const newY = textProps.y + (height - textProps.height) / 2
            const newAttrs = {
                ...textProps,
                text: textarea.value,
                asTyped: textarea.value,
                width,
                height,
                x: newX,
                y: newY,
                offsetX: width / 2,
                offsetY: height / 2,
            }
            onChange(newAttrs)
            textarea.parentNode.removeChild(textarea)
        }

        function setTextareaWidth(newWidth) {
            if (!newWidth) {
                // set width for placeholder
                newWidth = textNode.fontSize()
            }
            // some extra fixes on different browsers
            var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
            var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
            if (isSafari || isFirefox) {
                newWidth = Math.ceil(newWidth)
            }

            var isEdge = document.documentMode || /Edge/.test(navigator.userAgent)
            if (isEdge) {
                newWidth += 1
            }
            textarea.style.width = newWidth + 'px'
        }

        textarea.addEventListener('keydown', function (e) {
            e.stopPropagation()
            if (!textarea.value) return
            // hide on enter
            // but don't hide on shift + enter
            if (e.keyCode === 13 && !e.shiftKey) {
                textNode.text(textarea.value)
                removeTextarea()
            }
            // on esc do not set value back to node
            if (e.keyCode === 27) {
                removeTextarea()
            }
        })

        textarea.addEventListener('keyup', function (e) {
            e.stopPropagation()
        })

        function getWidth() {
            // Get the current text content of the div
            var text = textarea.value

            // Get the current font size of the div
            var fontSize = textarea.style.fontSize

            // Create a temporary span element to measure the width
            var span = document.createElement('span')
            span.style.fontSize = fontSize
            span.style.visibility = 'hidden'
            span.style.whiteSpace = 'pre' // Preserve white spaces

            // Set the text content of the span element
            span.textContent = text

            // Append the span to the document body
            document.body.appendChild(span)

            // Get the width of the span
            var width = span.offsetWidth

            // Remove the span from the document body
            document.body.removeChild(span)

            return width
        }

        function updateTextarea() {
            onHideToolbar()
            setTextareaWidth(getWidth() + textNode.fontSize() * ratio)
            textarea.style.height = 'auto'
            textarea.style.height = textarea.scrollHeight + textNode.fontSize() * ratio + 'px'
        }

        textarea.addEventListener('keydown', function (e) {
            e.stopPropagation()
            updateTextarea()
        })

        textarea.addEventListener('paste', function (e) {
            setTimeout(() => {
                updateTextarea()
            })
        })

        function handleOutsideClick(e) {
            if (!textarea) return
            if (e.target !== textarea) {
                textNode.text(textarea.value)
                removeTextarea()
            }
        }
        setTimeout(() => {
            window.addEventListener('click', handleOutsideClick)
            window.addEventListener('touchend', handleOutsideClick)
        })
    }

    const onItemClick = (event) => {
        const textNode = event.target
        if (!textNode || textNode.attrs.isLock) return

        onSelect(textNode)
        setTimeout(() => {
            onShowToolbar()
        }, 100)
    }

    const onDragEnd = (event) => {
        if (!event.target) return
        const textAttrs = event.target.attrs
        onChange(textAttrs)
        onShowToolbar()
    }

    const onTransformEnd = (event) => {
        if (!event.target) return

        const textNode = event.target
        const textAttrs = textNode.attrs

        const {fontSize, width, height, scaleX} = textAttrs
        const scaleRatio = scaleX / textProps.scaleX
        const newFontSize = Math.max(1, fontSize * scaleRatio)
        const newWidth = width * scaleRatio
        const newHeight = height * scaleRatio
        const offsetX = newWidth / 2
        const offsetY = newHeight / 2
        textNode.scaleX(1)
        textNode.scaleY(1)
        onChange({
            ...textAttrs,
            width: newWidth,
            height: newHeight,
            offsetX,
            offsetY,
            scaleX: 1,
            scaleY: 1,
            fontSize: newFontSize,
        })
        setTimeout(() => {
            onShowToolbar()
        }, 100)
    }

    const boundBoxFunc = (oldBox, newBox) => {
        if (newBox.width < MIN_TRANSFORM_WIDTH) {
            return oldBox
        }
        if (newBox.height < MIN_TRANSFORM_HEIGHT) {
            return oldBox
        }
        return newBox
    }

    const onHideToolbar = () => {
        const toolbar = document.getElementById('text-toolbar')
        if (toolbar) {
            toolbar.style.display = 'none'
        }
    }

    /**
     *
     * @param {import('konva/types/Node').KonvaEventObject<Event>} event
     */
    const onDragMove = (event) => {
        hideToolbar()

        const node = event.target
        const trNode = trRef.current
        if (!node && !trNode) return

        const {x: bgX, y: bgY, width: bgWidth, height: bgHeight, ratioDefault} = background
        const {x, y, width, height} = node.getClientRect({relativeTo: trNode})

        const absoluteX = x / ratioDefault
        const absoluteY = y / ratioDefault
        const absoluteWidth = width / ratioDefault
        const absoluteHeight = height / ratioDefault

        if (absoluteX + absoluteWidth < bgX) {
            node.x(bgX - absoluteWidth / 2)
        }

        if (absoluteY + absoluteHeight < bgY) {
            node.y(bgY - absoluteHeight / 2)
        }

        if (absoluteX > bgX + bgWidth) {
            node.x(bgX + bgWidth + absoluteWidth / 2)
        }
        if (absoluteY > bgY + bgHeight) {
            node.y(bgY + bgHeight + absoluteHeight / 2)
        }
    }

    const handleTextTransform = () => {
        const node = textRef.current
        const anchorType = trRef.current.getActiveAnchor()
        if (anchorType === 'middle-left' || anchorType === 'middle-right') {
            const scaleX = node.scaleX()
            const newWidth = Math.max(MIN_TRANSFORM_WIDTH, node.width() * scaleX)
            node.scaleX(1)
            onChange({
                ...textProps,
                width: newWidth,
                offsetX: newWidth / 2,
            })
        }
    }

    return (
        <Fragment>
            {hasArc ? (
                <Rect
                    {...textProps}
                    fill=""
                    width={textProps.width}
                    ref={textRef}
                    draggable={!isLock}
                    listening={!isLock}
                    fillPatternImage={image}
                    fillPatternRepeat="no-repeat"
                    globalCompositeOperation="source-atop"
                    onClick={onItemClick}
                    onTap={onItemClick}
                    onDragStart={onItemClick}
                    onDragMove={onDragMove}
                    onDragEnd={onDragEnd}
                    onTransformEnd={onTransformEnd}
                />
            ) : (
                <Text
                    {...textProps}
                    ref={textRef}
                    listening={!isLock}
                    draggable={!isLock}
                    fontFamily={textProps.fontFamily}
                    fontWeight={textProps.fontWeight || '400'}
                    fontStyle={textProps.fontStyle || ''}
                    globalCompositeOperation="source-atop"
                    onClick={onItemClick}
                    onTap={onItemClick}
                    onDragStart={onItemClick}
                    onDblClick={onDoubleClick}
                    onDblTap={onDoubleClick}
                    onDragMove={onDragMove}
                    onDragEnd={onDragEnd}
                    onTransformEnd={onTransformEnd}
                    onTransform={handleTextTransform}
                />
            )}

            {isSelected && (
                <Transformer
                    ref={trRef}
                    keepRatio={true}
                    name="transformer"
                    resizeEnabled={!isLock}
                    rotateEnabled={!isLock}
                    centeredScaling={true}
                    anchorCornerRadius={30}
                    rotateAnchorOffset={20}
                    enabledAnchors={[
                        'top-left',
                        'top-right',
                        'bottom-left',
                        'bottom-right',
                        'middle-left',
                        'middle-right',
                    ]}
                    boundBoxFunc={boundBoxFunc}
                    onTransform={hideToolbar}
                    rotateAnchorCursor="grabbing"
                />
            )}
        </Fragment>
    )
})

export default TextLayer
