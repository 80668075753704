import {useMemo} from 'react'
import {useSelectedVariantContext} from '../components/step2/context/SelectedVariantContext'
import {useEditorSides} from './useEditorSides'

export function useEditorLayers() {
    const {
        state: {selectedAttribute, selectedSide, designs},
    } = useSelectedVariantContext()

    const designsSpecified = designs.filter((design) => design.isDefault === false)
    let attIds = []
    designsSpecified.forEach((design) => {
        design.linkedAttrIds.forEach((id) => {
            if (!attIds.includes(id)) {
                attIds.push(id)
            }
        })
    })

    const {isOptionalSide} = useEditorSides()

    const layers = useMemo(() => {
        if (!selectedAttribute?._id || !selectedSide || isOptionalSide) return []
        const listDesign = designs.filter((design) => design.allAttrIds.includes(selectedAttribute._id))
        const design = designs.find((item) => {
            if (listDesign.length > 1 && attIds.includes(selectedAttribute._id)) {
                return item.linkedAttrIds.includes(selectedAttribute._id) && item.isDefault === false
            } else {
                return item.allAttrIds.includes(selectedAttribute._id)
            }
        })

        return design.side[selectedSide]?.layers || []
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAttribute?._id, selectedSide, designs])

    return layers
}

