import React from 'react'
import SwitchBetweenSides from './SwitchBetweenSides'
import TemplateHelper from './TemplateHelper'
import ShortcutHelper from './ShortcutHelper'
import withPopover from '../../../HOCs/withPopover'
import {Button} from 'reactstrap'
import {ReactComponent as KeyboardIcon} from '../../../assets/icons/keyboard.svg'
import {ReactComponent as TemplateIcon} from '../../../assets/icons/mockup_template.svg'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'

const PopoverIcon = withPopover(Button)

const SwitchBetweenSidesWithHelper = () => {
    const {
        state: {tabDesign, has3DPreview},
    } = useSelectedVariantContext()
    return (
        <div className={'MockupEditor__Bottom'}>
            <SwitchBetweenSides />
            <div
                className={'ExtraInformation list'}
                style={{
                    '--gap': '0.5rem',
                }}
            >
                <PopoverIcon
                    color={'default'}
                    className={'Icon shadow-1'}
                    ContentComponent={() => <TemplateHelper has3DPreview={has3DPreview} />}
                    placement={'top'}
                    trigger={'hover'}
                    popoverOffsetX={!!tabDesign ? 0 : -160}
                >
                    <div className={'d-flex align-items-center justify-content-center'}>
                        <TemplateIcon />
                    </div>
                </PopoverIcon>
                <PopoverIcon
                    color={'default'}
                    className={'Icon shadow-1'}
                    ContentComponent={ShortcutHelper}
                    placement={'top'}
                    trigger={'hover'}
                    popoverOffsetX={!!tabDesign ? 0 : -20}
                >
                    <div className={'d-flex align-items-center justify-content-center'}>
                        <KeyboardIcon />
                    </div>
                </PopoverIcon>
            </div>
        </div>
    )
}

export default SwitchBetweenSidesWithHelper
