import Bluebird from 'bluebird'

const cutDesignParts = async (design, templateConfig, scale) => {
    const start = Date.now()

    const designScale = design.width / templateConfig.width

    // crop designImage by safezones
    const result = await Bluebird.map(templateConfig.safezones, async (safezone) => {
        const partWidth = safezone.width * scale
        const partHeight = safezone.height * scale

        const canvas = new OffscreenCanvas(partWidth, partHeight)
        const ctx = canvas.getContext('2d')

        ctx.drawImage(
            design,
            safezone.x * designScale, safezone.y * designScale, safezone.width * designScale, safezone.height * designScale,
            0, 0, partWidth, partHeight,
        )

        return {
            image: canvas,
            width: partWidth,
            height: partHeight,
        };
    }, {concurrency: 3})

    console.debug(`[cutDesignParts] | ${Date.now() - start} ms`)
    return result
}

export default cutDesignParts
