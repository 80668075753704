import React, {Fragment, useCallback, useEffect} from 'react'
import {CompactPicker} from 'react-color'
import {UncontrolledPopover, UncontrolledTooltip} from 'reactstrap'
import {ReactComponent as FitToWidth} from '../../../assets/icons/arrow-h.svg'
import {ReactComponent as CropIcon} from '../../../assets/icons/crop.svg'
import {ReactComponent as FitToHeight} from '../../../assets/icons/arrow-v.svg'
import {ReactComponent as Duplicate} from '../../../assets/icons/duplicate.svg'
import {ReactComponent as HorizontalFlip} from '../../../assets/icons/horizontal-flip.svg'
import {ReactComponent as Trash} from '../../../assets/icons/trash-alt.svg'
import {ReactComponent as VerticalFlip} from '../../../assets/icons/vertical-flip.svg'
import {ReactComponent as RemoveBackground} from '../../../assets/icons/background.svg'
import {ReactComponent as PatternIcon} from '../../../assets/icons/pattern.svg'
import {useEditorBackground} from '../../../hooks/useEditorBackground'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'
import {
    actionChangeTabDesign,
    actionDuplicateLayer,
    removeLayer,
    updateLayerAttribute,
} from '../context/SelectedVariantContext/action'
import {CMYK_COLORS} from '../context/SelectedVariantContext/constants'
import {turnCmykToHex, turnHexToCmyk} from '../context/SelectedVariantContext/helper'
import classNames from 'classnames'
import LayerFontSelect from '../upload/tabs/campaign-design/LayerFontSelect'
import useFont from '../../../hooks/useFont'
import FormUpdateImagePattern from '../FormUpdateImagePattern'

const MockupEditorToolbar = () => {
    const {
        dispatch,
        state: {selectedLayer},
    } = useSelectedVariantContext()
    const {changeFont, fonts} = useFont()

    const [showFormUpdatePattern, setShowFormUpdatePattern] = React.useState(false)
    const imagePatternPopoverRef = React.useRef(null)

    // const layers = useEditorLayers()
    const background = useEditorBackground()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (imagePatternPopoverRef.current && !imagePatternPopoverRef.current.contains(event.target)) {
                setShowFormUpdatePattern(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const onChangeTextAlign = (align) => {
        if (!selectedLayer || selectedLayer?.isLock) return
        selectedLayer.align = align
        dispatch(updateLayerAttribute(selectedLayer))
    }

    const onChangeTextColor = (color) => {
        if (!selectedLayer || selectedLayer?.isLock) return

        let selectedColor = '#000000'
        if (color.hex !== '#000000') {
            // convert hex từ color picker sang cmyk để giảm mã màu
            const hexTocmyk = turnHexToCmyk(color.hex)

            // sau đó đổi lại từ cmyk sang hex để set vào layer (vì layer không nhận mã màu cmyk)
            selectedColor = turnCmykToHex(hexTocmyk)
        }
        selectedLayer.fill = selectedColor
        dispatch(updateLayerAttribute(selectedLayer))
    }

    const onFlip = (flipAxis) => {
        if (!selectedLayer || selectedLayer?.isLock) return

        dispatch(
            updateLayerAttribute({
                ...selectedLayer,
                scaleX: flipAxis === 'x' ? -1 * selectedLayer.scaleX : selectedLayer.scaleX,
                scaleY: flipAxis === 'y' ? -1 * selectedLayer.scaleY : selectedLayer.scaleY,
            }),
        )
    }

    const onFullAxis = (axis) => {
        if (!selectedLayer || selectedLayer.isLock) return

        const {x: bgX, y: bgY, width: bgWidth, height: bgHeight} = background
        const {width: selectedWidth, height: selectedHeight, scaleX, scaleY} = selectedLayer

        const newAttribute = {...selectedLayer}
        const scaleBg = axis === 'x' ? bgWidth / selectedWidth : bgHeight / selectedHeight

        if (axis === 'x') {
            newAttribute.x = bgX + bgWidth / 2
            newAttribute.y = bgY + bgHeight / 2
        } else {
            newAttribute.x = bgX + bgWidth / 2
            newAttribute.y = bgY + bgHeight / 2
        }

        newAttribute.scaleX = scaleX < 0 ? scaleBg * -1 : scaleBg
        newAttribute.scaleY = scaleY < 0 ? scaleBg * -1 : scaleBg

        dispatch(updateLayerAttribute(newAttribute))
    }

    /**
     * Handle duplicate layer for current stage side
     */
    const handleDuplicate = () => {
        if (!selectedLayer) return
        // if (selectedLayer.layerType === 'image') {
        //     const exceedsLimit = checkImageUploadLimit(layers)
        //     if (exceedsLimit)
        //         return toaster({
        //             type: 'error',
        //             message: '5 images are allowed to upload',
        //         })
        // }
        dispatch(actionDuplicateLayer(selectedLayer))
    }

    const handleDuplicateAllArea = () => {
        if (!selectedLayer) return
        dispatch(actionDuplicateLayer(selectedLayer, {scopeAll: true}))
    }

    const handleRemoveLayer = () => {
        if (!selectedLayer || selectedLayer?.isLock) return

        dispatch(removeLayer(selectedLayer))
    }

    const updateFontSelectedTextLayer = useCallback(changeFont(selectedLayer), [selectedLayer])

    const openLayerPopover = () => {
        dispatch(actionChangeTabDesign('layer'))
    }

    const imageToolbarActions = [
        {
            id: 'flipX',
            children: <HorizontalFlip width={20} height={20} />,
            label: 'Horizontal flip',
            attId: 'flip_horizontal',
            classes: 'toolbar-image',
            onClick: () => onFlip('x'),
        },
        {
            id: 'flipY',
            children: <VerticalFlip width={20} height={20} />,
            label: 'Vertical flip',
            attId: 'flip_vertical',
            classes: 'toolbar-image',
            onClick: () => onFlip('y'),
            endGroup: true,
        },
        {
            id: 'fitHeight',
            children: <FitToHeight width={20} height={20} />,
            label: 'Fit to height',
            attId: 'fit_height',
            classes: 'toolbar-image',
            onClick: () => onFullAxis('y'),
        },
        {
            id: 'fitWidth',
            children: <FitToWidth width={20} height={20} />,
            label: 'Fit to width',
            attId: 'fit_width',
            classes: 'toolbar-image',
            onClick: () => onFullAxis('x'),
            endGroup: true,
        },
        {
            id: 'removeBackground',
            label: 'Remove background',
            classes: 'toolbar-image',
            children: <RemoveBackground width={20} height={20} />,
            attId: '',
            onClick: () => {
                // TODO: handle remove background
            },
            hide: true,
        },
        {
            id: 'pattern',
            label: 'Pattern',
            classes: 'toolbar-image',
            attId: 'select_pattern ',
            children: (
                <>
                    <PatternIcon width={20} height={20} id={'pattern'} color="default" />
                    <div ref={imagePatternPopoverRef} onClick={(e) => e.stopPropagation()}>
                        <FormUpdateImagePattern
                            imageLayer={selectedLayer}
                            className={classNames('ImagePatternPopover', {
                                show: showFormUpdatePattern,
                            })}
                        />
                    </div>
                </>
            ),
            onClick: () => {
                setShowFormUpdatePattern(!showFormUpdatePattern)
            },
        },
        {
            id: 'crop',
            label: 'Crop',
            attId: '',
            classes: 'toolbar-image',
            children: <CropIcon width={20} height={20} />,
            onClick: () => {
                // TODO: handle crop image
            },
            hide: true,
        },
        {
            id: 'image_duplicate',
            attId: 'duplicate_image',
            label: 'Duplicate',
            classes: 'toolbar-image',
            children: <Duplicate width={20} height={20} />,
            onClick: handleDuplicate,
        },
        {
            id: 'image_remove',
            attId: 'delete_image',
            label: 'Remove',
            classes: 'toolbar-image',
            children: <Trash width={20} height={20} />,
            onClick: handleRemoveLayer,
            endGroup: true,
        },

        {
            id: 'image_duplicateAll',
            attId: 'apply_all_areas_image',
            children: 'Apply to all areas',
            classes: 'toolbar-image',
            onClick: handleDuplicateAllArea,
            endGroup: true,
        },
        {
            id: 'position',
            attId: 'position_image',
            children: 'Position',
            classes: 'toolbar-image',
            onClick: openLayerPopover,
        },
    ]

    const textToolbarActions = [
        {
            id: 'alignLeft',
            children: <i className="fa fa-align-left" />,
            label: 'Align left',
            classes: 'toolbar-text',
            onClick: () => onChangeTextAlign('left'),
        },
        {
            id: 'alignCenter',
            children: <i className="fa fa-align-center" />,
            label: 'Align center',
            classes: 'toolbar-text',
            onClick: () => onChangeTextAlign('center'),
        },
        {
            id: 'alignRight',
            children: <i className="fa fa-align-right" />,
            label: 'Align right',
            classes: 'toolbar-text',
            onClick: () => onChangeTextAlign('right'),
            endGroup: true,
        },
        {
            id: 'text_color',
            classes: 'toolbar-text',
            children: (
                <>
                    <i className="fa fa-font"></i>
                    <div className="TextColor" style={{backgroundColor: `black`}}></div>
                </>
            ),
            label: 'Change text color',
        },
        {
            id: 'text_duplicate',
            classes: 'toolbar-text',
            children: <Duplicate width={20} height={20} />,
            label: 'Duplicate',
            onClick: handleDuplicate,
        },
        {
            id: 'text_remove',
            classes: 'toolbar-text',
            children: <Trash width={20} height={20} />,
            label: 'Remove',
            onClick: handleRemoveLayer,
            endGroup: true,
        },
        {
            id: 'text_duplicateAll',
            classes: 'toolbar-text',
            children: 'Apply to all areas',
            onClick: handleDuplicateAllArea,
            endGroup: true,
        },
        {
            id: 'position',
            classes: 'toolbar-text',
            children: 'Position',
            onClick: openLayerPopover,
        },
    ]

    const renderToolbarActions = (type) => {
        const action = type === 'image' ? imageToolbarActions : textToolbarActions

        return action
            .filter((action) => !action.hide)
            .map((action) => {
                return (
                    <>
                        <ActionContainer key={action.id} {...action}>
                            {action.children}
                            {action?.label && (
                                <UncontrolledTooltip target={action.id} placement={'top'}>
                                    {action.label}
                                </UncontrolledTooltip>
                            )}
                        </ActionContainer>
                        {action?.endGroup && (
                            <div className={'flex-column flex-center'}>
                                <span
                                    className={'vr mx-2'}
                                    style={{
                                        height: '20px',
                                    }}
                                ></span>
                            </div>
                        )}
                    </>
                )
            })
    }

    return (
        selectedLayer && (
            <Fragment>
                <div className="MockupEditorToolbar ImageToolbar" id="image-toolbar">
                    {renderToolbarActions('image')}
                </div>

                <div className="MockupEditorToolbar TextToolbar" id="text-toolbar">
                    {fonts && (
                        <div className={'mr-2 flex-center'}>
                            <LayerFontSelect
                                fonts={fonts}
                                itemData={selectedLayer || {}}
                                // fontRandomId={fontRandomId}
                                handleChangeFont={updateFontSelectedTextLayer}
                            />
                        </div>
                    )}

                    {renderToolbarActions('text')}
                </div>

                {/*<div className="MockupEditorToolbar TextToolbar" id="text-toolbar">*/}
                {/*    {fonts && (*/}
                {/*        <LayerFontSelect*/}
                {/*            fonts={fonts}*/}
                {/*            itemData={selectedLayer || {}}*/}
                {/*            // fontRandomId={fontRandomId}*/}
                {/*            handleChangeFont={updateFontSelectedTextLayer}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*    <i*/}
                {/*        id="alignLeft"*/}
                {/*        className={classNames('mx-2 fa fa-align-left', {*/}
                {/*            active: selectedLayer && selectedLayer.align === 'left'*/}
                {/*        })}*/}
                {/*        onClick={() => onChangeTextAlign('left')}*/}
                {/*    />*/}
                {/*    <i*/}
                {/*        id="alignCenter"*/}
                {/*        className={classNames('mx-2 fa fa-align-center', {*/}
                {/*            active: selectedLayer && selectedLayer.align === 'center'*/}
                {/*        })}*/}
                {/*        onClick={() => onChangeTextAlign('center')}*/}
                {/*    />*/}

                {/*    <i*/}
                {/*        id="alignRight"*/}
                {/*        className={classNames(`mx-2 fa fa-align-right`, {*/}
                {/*            active: selectedLayer && selectedLayer.align === 'right'*/}
                {/*        })}*/}
                {/*        onClick={() => onChangeTextAlign('right')}*/}
                {/*    />*/}
                {/*    <div className={'vr mx-2'}></div>*/}
                {/*    <div className="mx-2" color="default" id="TextColorSelector" role={'button'}>*/}
                {/*        <i className="fa fa-font"></i>*/}
                {/*        <div className="TextColor" style={{backgroundColor: `black`}}></div>*/}
                {/*    </div>*/}
                {/*    <Duplicate*/}
                {/*        className="mx-2"*/}
                {/*        id={`text_duplicate`}*/}
                {/*        color="default"*/}
                {/*        onClick={handleDuplicate}*/}
                {/*        role={'button'}*/}
                {/*    />*/}
                {/*    <Trash*/}
                {/*        width={20}*/}
                {/*        height={20}*/}
                {/*        className="mx-2"*/}
                {/*        id={`text_remove`}*/}
                {/*        color="default"*/}
                {/*        onClick={handleRemoveLayer}*/}
                {/*        role={'button'}*/}
                {/*    />*/}
                {/*    <div className={'vr mx-2'}></div>*/}
                {/*    <div*/}
                {/*        className="mx-2"*/}
                {/*        id={`text_duplicateAll`}*/}
                {/*        color="default"*/}
                {/*        onClick={handleDuplicateAllArea}*/}
                {/*        role={'button'}*/}
                {/*    >*/}
                {/*        Apply to all areas*/}
                {/*    </div>*/}

                {/*    <div className={'vr mx-2'}></div>*/}
                {/*    <div role={'button'} onClick={openLayerPopover}>*/}
                {/*        Position*/}
                {/*    </div>*/}

                <UncontrolledPopover trigger="legacy" placement="top" target="text_color">
                    <CompactPicker
                        color={selectedLayer?.fill || '#ffffff'}
                        colors={CMYK_COLORS}
                        onChange={onChangeTextColor}
                    />
                </UncontrolledPopover>
                {/*</div>*/}

                {/*<ButtonTooltip />*/}
            </Fragment>
        )
    )
}

const ActionContainer = ({children, classes, ...rest}) => {
    return (
        <div
            className={classNames('Action flex-center position-relative rounded-sm', classes)}
            id={rest.attId}
            {...rest}
            role={'button'}
        >
            {children}
        </div>
    )
}

export default MockupEditorToolbar
