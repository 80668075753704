import React from 'react'
import {ReactComponent as ArrowUpIcon} from '../../../assets/icons/up.svg'
import {ReactComponent as ArrowDownIcon} from '../../../assets/icons/down.svg'
import {ReactComponent as ArrowLeftIcon} from '../../../assets/icons/left.svg'
import {ReactComponent as ArrowRightIcon} from '../../../assets/icons/right.svg'
import {isMacOs} from '../context/SelectedVariantContext/helper'

const shortcuts = [
    {
        shortcut: 'Move',
        macOsDescription: (
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gap: '2px',
                }}
            >
                <ArrowUpIcon />
                <ArrowDownIcon />
                <ArrowLeftIcon />
                <ArrowRightIcon />
            </div>
        ),
        description: (
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gap: '2px',
                }}
            >
                <ArrowUpIcon />
                <ArrowDownIcon />
                <ArrowLeftIcon />
                <ArrowRightIcon />
            </div>
        ),
    },
    {
        shortcut: 'Delete',
        macOsDescription: 'Cmd + Backspace',
        description: 'Delete',
        isEndGroup: true,
    },
    {
        shortcut: 'Copy',
        macOsDescription: 'Cmd + C',
        description: 'Ctrl + C',
    },
    {
        shortcut: 'Cut',
        macOsDescription: 'Cmd + X',
        description: 'Ctrl + X',
    },
    {
        shortcut: 'Paste',
        macOsDescription: 'Cmd + V',
        description: 'Ctrl + V',
        isEndGroup: true,
    },
    {
        shortcut: 'Undo',
        macOsDescription: 'Cmd + Z',
        description: 'Ctrl + Z',
    },
    {
        shortcut: 'Redo',
        macOsDescription: 'Cmd + Shift + z',
        description: 'Ctrl + Shift + z',
        // isEndGroup: true,
    },
    // {
    //     shortcut: 'Next',
    //     macOsDescription: 'Tab',
    //     description: 'Tab'
    // },
    // {
    //     shortcut: 'Previous',
    //     macOsDescription: 'Shift + Tab',
    //     description: 'Shift + Tab'
    // }
]

const ShortcutHelper = () => {
    return (
        <div className={'ShortcutHelper'}>
            {shortcuts.map((shortcut, index) => {
                return (
                    <>
                        <ShortcutItem
                            key={index}
                            shortcut={shortcut.shortcut}
                            description={isMacOs() ? shortcut.macOsDescription : shortcut.description}
                        />
                        {shortcut.isEndGroup && <hr />}
                    </>
                )
            })}
        </div>
    )
}

const ShortcutItem = ({shortcut, description}) => {
    return (
        <div className={'d-flex justify-content-between align-items-center my-2'}>
            <span>{shortcut}</span>
            <span>{description}</span>
        </div>
    )
}

export default ShortcutHelper
