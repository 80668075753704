import classNames from 'classnames'
import { Fragment, default as React, useCallback, useEffect, useRef, useState } from 'react'
import { Spinner } from 'reactstrap'
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg'
import { ReactComponent as Menu } from '../../assets/icons/menu.svg'
import { isBackofficeEnv } from '../../helpers/isBackoffceEnv'
import useFontSelector from '../../hooks/useFontSelector'
import useUploadFile from '../../hooks/useUploadFile'
import { getEnvironment, hasRole } from '../../services/AuthServices'
import { getCurrentStore } from '../../services/CurrentStoreServices'
import { getProductCatalogById, getWebsiteProductCatalogById } from '../../services/ProductLinesServices'
import { getLocalData, setLocalData } from '../../services/StorageServices'
import { toaster } from '../../shared/PFToast'
import NavBar from './NavBar'
import Toolbar from './Toolbar'
import MockupBottom from './artwork-editor/MockupBottom'
import MockupEditor from './artwork-editor/MockupEditor'
import PreviewLiveArtworkContainer from './artwork-editor/preview-live/PreviewLiveArtworkContainer'
import PreviewArtworkContainer from './artwork-editor/preview/PreviewArtworkContainer'
import { useSelectedVariantContext } from './context/SelectedVariantContext'
import {
    actionChangeTabDesign,
    actionInitProduct,
    actionSetIsAdmin,
    updateFonts,
} from './context/SelectedVariantContext/action'
import ProductVariantPricingContainer from './product-variant-pricing/ProductVariantPricingContainer'
import UploadContainer from './upload/UploadContainer'
import CampaignDesignLoading from './upload/tabs/campaign-design/CampaignDesignLoading'

const Step2Container = ({productId}) => {
    const {
        state: {tabDesign, display, product, runningImageCompress, imageCompressProgress},
        dispatch,
    } = useSelectedVariantContext()
    const {fonts} = useFontSelector()
    const [leftWidth, setLeftWidth] = useState(360)
    const separatorRef = useRef(null);
    const containerRef = useRef(null);
    const [minMaxLeftWidth, setMinMaxLeftWidth] = useState({min: 300, max: 460})
    const {pending} = useUploadFile()

    const env = getEnvironment()
    const [loading, setLoading] = useState(true)
    const designLibrary = getLocalData('designLibrary') || []
    const mockupData = product?.mockups[0]

    const handleMouseDown = (event) => {
        event.preventDefault()
        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)
    }

    const handleMouseMove = (event) => {
        if (!containerRef.current) return
        const containerRect = containerRef.current.getBoundingClientRect()
        const newLeftWidth = event.clientX - containerRect.left

        if (newLeftWidth > minMaxLeftWidth.min && newLeftWidth < minMaxLeftWidth.max) {
            setLeftWidth(newLeftWidth);
        }
    }

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
    }

    const onCloseSidebar = () => {
        dispatch(actionChangeTabDesign(''))
    }

    const getSidebar3DWidth = () => {
        if (document.getElementById('left-sidebar')) {
            setLeftWidth(document.getElementById('left-sidebar').offsetWidth)
        }
        // setLeftWidth(document.getElementById('left-sidebar'))
    }

    const _updateFonts = useCallback(() => {
        dispatch(updateFonts(fonts))
    }, [dispatch, fonts])

    useEffect(() => {
        _updateFonts()
    }, [_updateFonts])

    const getMaxLeftWidth = () => {
        if (window.innerWidth > 1700) {
            setMinMaxLeftWidth({min: 300, max: 460})
            return
        } else if (window.innerWidth > 1500) {
            setMinMaxLeftWidth({min: 228, max: 378})
            return
        }
        setMinMaxLeftWidth({min: 228, max: 378})
    }

    useEffect(() => {
        const _fetchProductCatalog = async () => {
            try {
                let response
                if (isBackofficeEnv()) {
                    const storeId = getCurrentStore() || ''
                    response = await getProductCatalogById(productId, storeId)
                } else {
                    response = await getWebsiteProductCatalogById(productId)
                    if (designLibrary.length > 0) {
                        const now = new Date()
                        const availableDesigns = designLibrary.filter((design) => {
                            const timeDifference = now - new Date(design.created)
                            const hoursDifference = timeDifference / (1000 * 60 * 60)
                            return hoursDifference <= 1
                        })
                        setLocalData('designLibrary', availableDesigns)
                    }
                }
                const {success, data, message} = response
                if (!success) throw new Error(message)
                const isAdmin = hasRole('admin')
                dispatch(actionSetIsAdmin(isAdmin))
                dispatch(actionInitProduct(data))
            } catch (error) {
                toaster({
                    type: 'error',
                    message: error.message,
                })
            } finally {
                setLoading(false)
            }
        }

        _fetchProductCatalog()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, env, dispatch])

    useEffect(() => {
        getSidebar3DWidth()
        window.addEventListener('resize', getSidebar3DWidth)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mockupData])

    useEffect(() => {
        getMaxLeftWidth()
        window.addEventListener('resize', getMaxLeftWidth)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const className = display === 'design' ? 'd-block' : 'd-none'

    return (
        <div className="Step2Container newVersion" style={{overflow: 'hidden', maxHeight: '100vh'}}>
            {/* <DocTitleByStore title="Mockup generator" /> */}
            {loading ? (
                <div className="LoadingWrapper">
                    <Spinner style={{color: '#007BFF'}} />
                </div>
            ) : product ? (
                <Fragment>
                    <NavBar />
                    <div
                        className={classNames(
                            'Wrapper position-relative',
                            {Design: display === 'design'},
                            {Pricing: display === 'pricing'},
                        )}
                        ref={containerRef}
                    >
                        {display === 'design' && mockupData.meta.version === 'v3' && !!mockupData.preview_v3 && (
                            <>
                                <div id='left-sidebar' className='position-relative pt-3 Left'
                                    style={{width: `${!!leftWidth ? `${leftWidth}px` : 'revert-layer'}`}}>
                                    <PreviewLiveArtworkContainer />
                                </div>
                                <div className="separator" ref={separatorRef} onMouseDown={handleMouseDown}>
                                    <Menu />
                                </div>
                            </>
                        )}
                        <div className="Center NewVersion">
                            <div className={className}>
                                <MockupEditor product={product} />
                            </div>
                            {display === 'preview' && <PreviewArtworkContainer display={display} />}
                            {['design', 'preview'].includes(display) && <MockupBottom />}
                            {display === 'pricing' && <ProductVariantPricingContainer display={display} />}
                        </div>

                        {display === 'design' && !tabDesign && (pending || runningImageCompress) && (
                            <div id="right-sidebar" className="position-absolute RightLoading">
                                <CampaignDesignLoading percentage={imageCompressProgress} />
                            </div>
                        )}

                        {(display !== 'design' || (display === 'design' && tabDesign)) && (
                            <div
                                id="right-sidebar"
                                className={`${display === 'design' ? 'position-absolute' : 'position-relative'} Right`}
                            >
                                <UploadContainer />
                                {tabDesign && display === 'design' && (
                                    <div className="iconCloseSidebar" onClick={onCloseSidebar} id="close_tab">
                                        <ArrowRight />
                                    </div>
                                )}
                            </div>
                        )}

                        {display === 'design' && (
                            <div id="toolbar" className="Toolbar">
                                <Toolbar />
                            </div>
                        )}
                    </div>
                </Fragment>
            ) : (
                <Fragment />
            )}
        </div>
    )
}

export default Step2Container
