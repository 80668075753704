import classNames from 'classnames'
import React, {Component, useEffect, useRef, useState} from 'react'
import {Group, Image, Layer, Stage, Text} from 'react-konva'
import {Button, Input, InputGroup, UncontrolledTooltip} from 'reactstrap'
import {ReactComponent as Minus} from '../../../assets/icons/minus.svg'
import {ReactComponent as Palm} from '../../../assets/icons/palm.svg'
import {ReactComponent as Plus} from '../../../assets/icons/plus.svg'
import {usePreviewArtworks} from '../../../hooks/usePreviewArtworks'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'
import {SCALE_TYPE} from '../context/SelectedVariantContext/constants'
import {formatMockupSideName, roundDecimal} from '../context/SelectedVariantContext/helper'
import SwitchBetweenSidesWithHelper from './SwitchBetweenSidesWithHelper'

const MockupAllAreas = ({activeSide, classes}) => {
    const {
        state: {selectedAttribute, editorWrapper},
    } = useSelectedVariantContext()

    const {fetchAreas} = usePreviewArtworks()

    const [loading, setLoading] = useState(false)
    const [areas, setAreas] = useState([])
    const [scale, setScale] = useState(1)
    const [stage, setStage] = useState({
        scale: 1,
        x: 0,
        y: 0,
    })
    const [mockup, setMockup] = useState(null)
    const allAreaRef = useRef(null)
    const [stageDrag, setStageDrag] = useState(false)

    useEffect(() => {
        if (!selectedAttribute?._id || !editorWrapper || activeSide !== 'All') return
        let blob = null

        const fetchArea = async () => {
            try {
                // setLoading(true)
                const areaInfo = await fetchAreas()
                setAreas(areaInfo.artworks)
                setMockup(areaInfo.mockup.preview['Front'])
                const clientWidth = editorWrapper.width
                const clientHeight = editorWrapper.height
                const scaleX = areaInfo.mockup.preview['Front']?.image_width / clientWidth
                const scaleY = areaInfo.mockup.preview['Front']?.image_height / clientHeight
                const scale = Math.max(scaleX, scaleY)
                setScale(scale)
            } finally {
                setLoading(false)
            }
        }

        fetchArea()

        return () => URL.revokeObjectURL(blob)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAttribute?._id, activeSide, editorWrapper])

    const _handleWheel = (e) => {
        e.evt.preventDefault()
        const scaleBy = 1.1
        const stage = e.target.getStage()
        const oldScale = stage.scaleX()
        const mousePointTo = {
            x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
            y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
        }

        const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy
        if (newScale < 0.2 || newScale > 1) return

        setStage({
            scale: newScale,
            x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
            y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
        })
    }

    const onScaleStage = (type) => {
        const scaleFactor = type === SCALE_TYPE.MINUS ? 1 / 1.1 : 1.1
        const newScale = stage.scale * scaleFactor
        if (newScale < 0.2 || newScale > 1) return
        const centerWidth = editorWrapper.width / 2
        const centerHeight = editorWrapper.height / 2
        setStage({
            scale: newScale,
            x: centerWidth - (centerWidth - stage.x) * scaleFactor, // center X
            y: centerHeight - (centerHeight - stage.y) * scaleFactor, // center Y
        })
    }

    const _onToggleDragStage = () => setStageDrag((prev) => !prev)

    return (
        <div className={classes} ref={allAreaRef}>
            <SwitchBetweenSidesWithHelper />
            {/* {loading && (
                <div className="SpinnerWrapper">
                    <div className="text-primary spinner-border Spinner" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )} */}
            <div className={`AllAreaStage ${loading ? 'd-none' : 'd-block'}`}>
                {mockup && (
                    <Stage
                        x={stage.x}
                        y={stage.y}
                        scaleX={stage.scale}
                        scaleY={stage.scale}
                        draggable={stageDrag}
                        width={editorWrapper.width}
                        height={editorWrapper.height}
                        onWheel={_handleWheel}
                        onMouseEnter={(e) => {
                            const container = e.target.getStage().container()
                            container.style.cursor = stageDrag ? 'pointer' : 'default'
                        }}
                        onMouseDown={(e) => {
                            const container = e.target.getStage().container()
                            container.style.cursor = stageDrag ? 'grabbing' : 'default'
                        }}
                        onMouseUp={(e) => {
                            const container = e.target.getStage().container()
                            container.style.cursor = stageDrag ? 'pointer' : 'default'
                        }}
                        onMouseLeave={(e) => {
                            const container = e.target.getStage().container()
                            container.style.cursor = 'default'
                        }}
                    >
                        <Layer>
                            <Group
                                width={mockup.image_width / (scale * 2)}
                                height={mockup.image_height / (scale * 2)}
                                x={(editorWrapper.width - mockup.image_width / scale) / 2}
                                y={(editorWrapper.height - mockup.image_height / scale) / 2}
                            >
                                {areas.map((area) => {
                                    if (!area) return null
                                    const {src, width, height, x, y, side} = area
                                    const {addition_position = {}} = selectedAttribute?.mockup?.preview_v2[side]
                                    const {left = 0, top = 0} = addition_position
                                    return (
                                        <URLImage
                                            src={src}
                                            side={side}
                                            width={width / scale}
                                            height={height / scale}
                                            x={(left + x) / scale}
                                            y={(top + y) / scale}
                                        />
                                    )
                                })}
                            </Group>
                        </Layer>
                    </Stage>
                )}
            </div>

            <div className="MockupBottom">
                <div className="BottomLeft" />
                <div className="BottomRight d-flex">
                    <div
                        id="dragStage"
                        className={classNames('MockupDrag me-2', {Active: stageDrag})}
                        onClick={_onToggleDragStage}
                    >
                        <Palm width={20} height={20} />
                    </div>
                    <div className="MockupZoom">
                        <InputGroup>
                            <Button
                                id="btn_minus"
                                className="btn-minus"
                                color="default"
                                onClick={() => onScaleStage(SCALE_TYPE.MINUS)}
                            >
                                <Minus width={16} height={16} />
                            </Button>
                            <Input
                                className="TextRatio"
                                type="text"
                                value={`${roundDecimal(stage.scale * 100)}%`}
                                readOnly
                            />
                            <Button
                                id="btn_plus"
                                className="btn-plus"
                                color="default"
                                onClick={() => onScaleStage(SCALE_TYPE.PLUS)}
                            >
                                <Plus width={16} height={16} />
                            </Button>
                        </InputGroup>
                    </div>
                    <UncontrolledTooltip target="dragStage">Drag editor</UncontrolledTooltip>
                    <UncontrolledTooltip target="btn_minus">Zoom out</UncontrolledTooltip>
                    <UncontrolledTooltip target="btn_plus">Zoom in</UncontrolledTooltip>
                </div>
            </div>
        </div>
    )
}

class URLImage extends Component {
    state = {
        image: null,
    }

    componentDidMount() {
        this.loadImage()
    }

    componentDidUpdate(oldProps) {
        if (oldProps.src !== this.props.src) {
            this.loadImage()
        }
    }

    componentWillUnmount() {
        this.image.removeEventListener('load', this.handleLoad)
    }

    loadImage() {
        this.image = new window.Image()
        this.image.src = this.props.src
        this.image.addEventListener('load', this.handleLoad)
    }

    handleLoad = () => {
        this.setState({
            image: this.image,
        })
    }

    renderLayers = () => {
        let {x, y, width, height, side} = this.props
        if (side.includes('neck') || side.includes('yoke')) {
            y -= 60
        } else if (side.includes('neck_right') || side.includes('hood') || side.includes('pocket')) {
            y -= 60
        } else if (side.includes('neck_left')) {
            y -= 20
        } else if (side.includes('hem') || side.includes('cuff')) {
            y += 60
        }

        return (
            <>
                <Image
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    image={this.state.image}
                    ref={(node) => {
                        this.imageNode = node
                    }}
                />
                <Text
                    x={x + (width - side.length * 10) / 2}
                    y={y - 20}
                    text={formatMockupSideName(side)}
                    fontSize={16}
                    fill="black"
                />
            </>
        )
    }

    render() {
        return <>{this.renderLayers()}</>
    }
}

export default MockupAllAreas
