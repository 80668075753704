import React, {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import withContainer from './withContainer'

const withPopover = (WrappedComponent) => {
    return ({
        placement = 'top', // Mặc định arrow ở dưới popover
        ContentComponent,
        contentProps = {},
        popoverOffsetX = 0,
        popoverOffsetY = 0,
        trigger = 'click',
        borderColor,
        ...props
    }) => {
        const [isOpen, setIsOpen] = useState(false)
        const triggerRef = useRef(null)

        const showPopover = () => {
            setIsOpen(true)
        }
        const hidePopover = () => {
            setIsOpen(false)
        }
        const togglePopover = () => setIsOpen((prev) => !prev)

        const eventHandlers =
            trigger === 'click' ? {onClick: togglePopover} : {onMouseEnter: showPopover, onMouseLeave: hidePopover}

        return (
            <>
                <div
                    ref={triggerRef}
                    {...eventHandlers}
                    style={{display: 'inline-block', cursor: 'pointer'}}
                    className={'popover-container'}
                >
                    <WrappedComponent {...props} />
                </div>

                {isOpen &&
                    createPortal(
                        <PopoverContainer
                            targetRef={triggerRef}
                            placement={placement}
                            popoverOffsetX={popoverOffsetX}
                            popoverOffsetY={popoverOffsetY}
                            borderColor={borderColor}
                            className={'p-0'}
                        >
                            {ContentComponent ? <ContentComponent {...contentProps} /> : null}
                        </PopoverContainer>,
                        document.body,
                    )}
            </>
        )
    }
}

const PopoverContainer = withContainer(
    ({targetRef, placement, popoverOffsetX, popoverOffsetY, borderColor, children}) => {
        const popoverRef = useRef(null)
        const [style, setStyle] = useState({})
        const [arrowStyle, setArrowStyle] = useState({})
        const [arrowClass, setArrowClass] = useState('custom-arrow')

        useEffect(() => {
            if (!targetRef.current || !popoverRef.current) return

            const rect = targetRef.current.getBoundingClientRect() // toạ độ anchor
            const popoverRect = popoverRef.current.getBoundingClientRect() // toạ độ popover (khung)

            // 1. Tính toạ độ của popover
            let popoverLeft = 0
            let popoverTop = 0

            // Tâm anchor
            const anchorCenterX = rect.left + rect.width / 2 - 10
            const anchorCenterY = rect.top + rect.height / 2

            const arrowSize = 10 // Độ dài mũi tên
            switch (placement) {
                case 'top':
                    popoverLeft = anchorCenterX - popoverRect.width / 2 + popoverOffsetX
                    popoverTop = rect.top - popoverRect.height - arrowSize + popoverOffsetY
                    setArrowClass('custom-arrow custom-arrow-top')
                    break

                case 'bottom':
                    popoverLeft = anchorCenterX - popoverRect.width / 2 + popoverOffsetX
                    popoverTop = rect.bottom + arrowSize + popoverOffsetY
                    setArrowClass('custom-arrow custom-arrow-bottom')
                    break

                case 'left':
                    popoverLeft = rect.left - popoverRect.width - arrowSize + popoverOffsetX
                    popoverTop = anchorCenterY - popoverRect.height / 2 + popoverOffsetY
                    setArrowClass('custom-arrow custom-arrow-left')
                    break

                case 'right':
                    popoverLeft = rect.right + arrowSize + popoverOffsetX
                    popoverTop = anchorCenterY - popoverRect.height / 2 + popoverOffsetY
                    setArrowClass('custom-arrow custom-arrow-right')
                    break

                default:
                    // fallback = top
                    popoverLeft = anchorCenterX - popoverRect.width / 2 + popoverOffsetX
                    popoverTop = rect.top - popoverRect.height - arrowSize + popoverOffsetY
                    setArrowClass('custom-arrow custom-arrow-top')
                    break
            }

            // Cập nhật style cho popover
            const newStyle = {
                left: popoverLeft,
                top: popoverTop,
            }

            // 2. Tính toạ độ của mũi tên so với popover
            //  -- Tức là anchorCenterX (hoặc anchorCenterY) - popoverLeft = khoảng cách
            //  từ cạnh trái (hoặc trên) của popover tới tâm anchor.
            let newArrowStyle = {}
            switch (placement) {
                case 'top':
                case 'bottom': {
                    // arrowX = anchorCenterX - popoverLeft
                    // Rồi thêm transform: translateX(-50%) để mũi tên căn giữa
                    const arrowX = anchorCenterX - popoverLeft
                    newArrowStyle = {
                        left: `${arrowX}px`,
                        transform: 'translateX(-50%)',
                    }
                    if (placement === 'top') {
                        newArrowStyle.bottom = `-${arrowSize}px`
                    } else {
                        newArrowStyle.top = `-${arrowSize}px`
                    }
                    break
                }
                case 'left':
                case 'right': {
                    const arrowY = anchorCenterY - popoverTop
                    newArrowStyle = {
                        top: `${arrowY}px`,
                        transform: 'translateY(-50%)',
                    }
                    if (placement === 'left') {
                        newArrowStyle.right = `-${arrowSize}px`
                    } else {
                        newArrowStyle.left = `-${arrowSize}px`
                    }
                    break
                }
                default:
                    break
            }

            setStyle(newStyle)
            setArrowStyle(newArrowStyle)
        }, [targetRef, placement, popoverOffsetX, popoverOffsetY])

        return (
            <div ref={popoverRef} className="custom-popover popover-content shadow-2" style={style}>
                {children}
                <div className={arrowClass} style={{...arrowStyle, '--border-color': borderColor}} />
            </div>
        )
    },
)

export default withPopover
