import React, {Fragment, memo, useEffect, useState} from 'react'
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    UncontrolledPopover,
    UncontrolledTooltip,
} from 'reactstrap'
import {PATTERN_TYPE} from '../../../../../../constant'
import PropTypes from 'prop-types'
import {updateLayerAttribute} from '../../../../context/SelectedVariantContext/action'
import NumberFormat from 'react-number-format'
import {CompactPicker} from 'react-color'
import {CMYK_COLORS} from '../../../../context/SelectedVariantContext/constants'
import useImagePattern from '../../../../../../hooks/useImagePattern'

const ImageEffect = memo(({layer, dispatch}) => {
    const [openEffectDropdown, setOpenEffectDropdown] = useState('')
    const {onChangePatternSpacing, togglePattern} = useImagePattern()

    useEffect(() => {
        if (layer?.hasPattern) {
            setOpenEffectDropdown('imageEffect')
        }
    }, [layer])

    const handleChangeVerticalSpacing = onChangePatternSpacing('verticalSpacing')
    const handleChangeHorizontalSpacing = onChangePatternSpacing('horizontalSpacing')

    // const _toggleSwitch = (event) => {
    //     const {name, checked} = event.target
    //     layer[name] = checked
    //     dispatch(updateLayerAttribute(layer))
    // }

    const _changeEffects = (effect) => {
        const {name, value} = effect
        layer[name] = value
        dispatch(updateLayerAttribute(layer))
    }

    const _handleColorPicker = (color) => {
        if (!layer || layer.isLock) return
        layer.stroke = color.hex
        layer.shadowColor = color.hex
        dispatch(updateLayerAttribute(layer))
    }

    const _handleToggle = (targetId) => {
        if (openEffectDropdown === targetId) {
            setOpenEffectDropdown('')
            return
        }
        setOpenEffectDropdown(targetId)
    }

    return (
        <Accordion open={openEffectDropdown} toggle={_handleToggle} className="Effects ImageEffects">
            <AccordionItem>
                <AccordionHeader targetId="imageEffect">More effects</AccordionHeader>
                <AccordionBody accordionId="imageEffect">
                    {/*<div className="d-flex align-items-center">*/}
                    {/*    <Label className="switch-label me-2 fw-500" htmlFor="switchPattern">*/}
                    {/*        Drop shadow*/}
                    {/*    </Label>*/}
                    {/*    <FormGroup switch>*/}
                    {/*        <Input*/}
                    {/*            id="switchPattern"*/}
                    {/*            type="switch"*/}
                    {/*            name="hasDropShadow"*/}
                    {/*            checked={layer.hasDropShadow}*/}
                    {/*            onChange={_toggleSwitch}*/}
                    {/*            className="switch-input"*/}
                    {/*        />*/}
                    {/*    </FormGroup>*/}
                    {/*</div>*/}
                    {layer.hasDropShadow && (
                        <Fragment>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <Label className="mb-0">Opacity</Label>
                                    <InputGroup>
                                        <NumberFormat
                                            className="form-control"
                                            value={layer.hasDropShadow ? layer.shadowOpacity : 0}
                                            disabled={layer.isLock}
                                            decimalScale={2}
                                            autoComplete="off"
                                            allowLeadingZeros={false}
                                            onValueChange={(event) => {
                                                const {floatValue} = event
                                                _changeEffects({value: floatValue, name: 'shadowOpacity'})
                                            }}
                                        />
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroup>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-4">
                                    <Label className="mb-0">Size</Label>
                                    <NumberFormat
                                        className="form-control"
                                        value={layer.hasDropShadow ? layer.strokeWidth : 0}
                                        disabled={layer.isLock}
                                        decimalScale={2}
                                        autoComplete="off"
                                        allowLeadingZeros={false}
                                        onValueChange={(event) => {
                                            const {floatValue} = event
                                            _changeEffects({value: floatValue, name: 'strokeWidth'})
                                        }}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Label className="mb-0">Blur</Label>
                                    <NumberFormat
                                        className="form-control"
                                        value={layer.hasDropShadow ? layer.shadowBlur : 0}
                                        disabled={layer.isLock}
                                        decimalScale={2}
                                        autoComplete="off"
                                        allowLeadingZeros={false}
                                        onValueChange={(event) => {
                                            const {floatValue} = event
                                            _changeEffects({value: floatValue, name: 'shadowBlur'})
                                        }}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Label className="mb-0 opacity-0">Stroke</Label>
                                    <div className="DropShadowInputWrapper">
                                        <div id="DropShadowColorPicker" className="me-2">
                                            <div
                                                className="Icon"
                                                style={{backgroundColor: layer?.stroke || '#000000'}}
                                            ></div>

                                            <UncontrolledPopover
                                                trigger="legacy"
                                                placement="left"
                                                target="DropShadowColorPicker"
                                            >
                                                <CompactPicker
                                                    color={layer.stroke || '#000000'}
                                                    colors={CMYK_COLORS}
                                                    onChange={_handleColorPicker}
                                                />
                                            </UncontrolledPopover>
                                            <UncontrolledTooltip target="DropShadowColorPicker">
                                                Change stroke color
                                            </UncontrolledTooltip>
                                        </div>
                                        <Input
                                            type="text"
                                            value={layer.stroke}
                                            disabled={layer.isLock}
                                            className="form-control text-right"
                                            onChange={(event) => {
                                                const {value} = event.target
                                                _changeEffects({value, name: 'stroke'})
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                    <div className="d-flex align-items-center mb-2">
                        <Label className="switch-label me-2 fw-500" htmlFor="switchPattern">
                            Pattern
                        </Label>
                        <FormGroup switch>
                            <Input
                                id="switchPattern"
                                type="switch"
                                name="hasPattern"
                                checked={layer.hasPattern}
                                onChange={togglePattern}
                                className="switch-input"
                            />
                        </FormGroup>
                    </div>
                    {layer.hasPattern && (
                        <Fragment>
                            {/*<FormUpdateImagePattern imageLayer={layer} />*/}
                            <FormGroup>
                                <Label for="pattern">Pattern type</Label>
                                <Input
                                    className={'fs-14'}
                                    id="pattern"
                                    name="patternType"
                                    type="select"
                                    value={layer.patternType}
                                    onChange={(event) => {
                                        const {name, value} = event.target
                                        layer[name] = value
                                        dispatch(updateLayerAttribute(layer))
                                    }}
                                >
                                    <option value={PATTERN_TYPE.GRID_PATTERN}>Grid pattern</option>
                                    <option value={PATTERN_TYPE.HORIZONTAL}>Horizontal</option>
                                    <option value={PATTERN_TYPE.VERTICAL}>Vertical</option>
                                    <option value={PATTERN_TYPE.HORIZONTAL_BRICK}>Horizontal brick</option>
                                    <option value={PATTERN_TYPE.VERTICAL_BRICK}>Vertical brick</option>
                                </Input>
                            </FormGroup>
                            <div className="row mb-3">
                                <div className="col-sm-6">
                                    <Label className="mb-0">Horizontal spacing</Label>
                                    <InputGroup>
                                        <NumberFormat
                                            className="form-control"
                                            value={layer.horizontalSpacing}
                                            disabled={layer.isLock}
                                            decimalScale={2}
                                            autoComplete="off"
                                            allowLeadingZeros={false}
                                            onValueChange={(event) => {
                                                const {floatValue} = event
                                                handleChangeHorizontalSpacing(floatValue)
                                            }}
                                        />
                                        <InputGroupText>px</InputGroupText>
                                    </InputGroup>
                                </div>
                                <div className="col-sm-6">
                                    <Label className="mb-0">Vertical spacing</Label>
                                    <InputGroup>
                                        <NumberFormat
                                            className="form-control"
                                            value={layer.verticalSpacing}
                                            disabled={layer.isLock}
                                            decimalScale={2}
                                            autoComplete="off"
                                            allowLeadingZeros={false}
                                            onValueChange={(event) => {
                                                const {floatValue} = event
                                                handleChangeVerticalSpacing(floatValue)
                                            }}
                                        />
                                        <InputGroupText>px</InputGroupText>
                                    </InputGroup>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </AccordionBody>
            </AccordionItem>
        </Accordion>
    )
})

ImageEffect.propTypes = {
    layer: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
}

export default ImageEffect
