import React from 'react'
import FontSelector from '../../../../../shared/FontSelector'
import randomstring from 'randomstring'

const LayerFontSelect = ({itemData, fonts, handleChangeFont}) => {
    console.log('itemData', itemData)
    const id = itemData?.fontId || randomstring.generate(7)

    const _fontSpecToComponents = (value) => {
        let tmp = value.split(':'),
            family = tmp[0],
            variant = tmp[1] || '400',
            type = tmp[2],
            style = '',
            weight

        if (/(\d+)i$/.test(variant)) {
            style = 'italic'
            weight = +RegExp.$1
        } else {
            weight = +variant
        }

        return {
            family,
            weight,
            style,
            type,
        }
    }

    const _updateFontValue = (fonts = [], value) => {
        const {family, weight, style, type} = _fontSpecToComponents(value || 'Arial:400:local')
        const updateVal = {
            fontFamily: family,
            fontWeight: weight.toString(),
            fontStyle: style,
            fontType: type,
            linkFont: '',
        }

        if (type !== 'local') {
            const selectedFontData = fonts[`${type}Fonts`][family]

            if (selectedFontData) {
                const {files} = selectedFontData
                let key = weight

                if (type === 'google') {
                    key = weight + style.replace('italic', 'i')
                    updateVal.linkFont = files[key]
                    updateVal.fontFamily = family + key // avoid duplicate family name with other variants
                } else if (type === 'custom') {
                    updateVal.linkFont = files
                }
            }
        }
        return updateVal
    }

    const _handleChangeFont = (value) => {
        const updateVal = _updateFontValue(fonts, value)
        handleChangeFont({
            ...updateVal,
            fontId: id,
        })
    }

    return (
        <FontSelector
            fonts={fonts}
            itemData={itemData}
            itemId={id}
            changeHandler={(event) => _handleChangeFont(event)}
        />
    )
}

export default LayerFontSelect
