import React from 'react'
import useImagePattern from '../../hooks/useImagePattern'
import {Input, Label} from 'reactstrap'
import {PATTERN_TYPE} from '../../constant'
import withContainer from '../../HOCs/withContainer'

const FormUpdateImagePattern = ({imageLayer}) => {
    const {disablePattern, onChangeImagePattern} = useImagePattern(imageLayer)

    const handleChangePatternType = (type) => {
        if (type === PATTERN_TYPE.NONE) {
            return disablePattern()
        }

        return onChangeImagePattern(type)
    }

    return (
        imageLayer && (
            <div className={'mb-0'}>
                <Label for="pattern">Pattern type</Label>
                <Input
                    className={'fs-14'}
                    id="pattern"
                    type="select"
                    value={imageLayer?.hasPattern ? imageLayer.patternType : PATTERN_TYPE.NONE}
                    onChange={(event) => {
                        handleChangePatternType(event.target.value)
                    }}
                >
                    <option value={PATTERN_TYPE.NONE}>None</option>
                    <option value={PATTERN_TYPE.GRID_PATTERN}>Grid pattern</option>
                    <option value={PATTERN_TYPE.HORIZONTAL}>Horizontal</option>
                    <option value={PATTERN_TYPE.VERTICAL}>Vertical</option>
                    <option value={PATTERN_TYPE.HORIZONTAL_BRICK}>Horizontal brick</option>
                    <option value={PATTERN_TYPE.VERTICAL_BRICK}>Vertical brick</option>
                </Input>
            </div>
        )
    )
}

export default withContainer(FormUpdateImagePattern)
