import classNames from "classnames"
import { debounce } from 'lodash'
import { memo, useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import { ReactComponent as ChevronLeft } from '../../../../assets/icons/chevron-left.svg'
import { ReactComponent as ChevronRight } from '../../../../assets/icons/chevron-right.svg'
import { usePreviewArtworks } from "../../../../hooks/usePreviewArtworks"
import PubSub from '../../Broker'
import { useSelectedVariantContext } from "../../context/SelectedVariantContext"
import { actionUpdatePreviewImages3D } from "../../context/SelectedVariantContext/action"
import { destroyBabylonInstance, getBabylonInstance } from "./core/Babylon"
import cutDesignParts from "./core/cutDesignParts"

const PreviewLiveArtworkContainer = memo(() => {
    const {
        state: { product, designs, selectedSide, selectedAttribute, loadingImageLayer, pickerAttributes },
        dispatch,
    } = useSelectedVariantContext()
    const {fetchPreview3D} = usePreviewArtworks()

    const {
        preview_v3: mockupPreviewV3Config,
    } = product.mockups[0]

    const renderCanvasRef = useRef(null)

    const [previewMode, setPreviewMode] = useState('3d')
    const [mockupViews, setMockupViews] = useState([])
    const [selectedMockupView, setSelectedMockupView] = useState(0)

    const switchPreview = (mode) => {
        if (mode === previewMode) return
        setPreviewMode(mode)
    }

    const start3D = async () => {
        const babylon = getBabylonInstance(
            renderCanvasRef.current,
            mockupPreviewV3Config,
        )

        if (mockupPreviewV3Config.mockup_views) {
            babylon.attachMockupCamera('mockup_canvas', mockupPreviewV3Config.mockup_views)
        }
        
        babylon.loadMaterialTexture(mockupPreviewV3Config.material_url, mockupPreviewV3Config.texture)
        await babylon.loadModel(mockupPreviewV3Config.model_url, mockupPreviewV3Config.material_id)
        babylon.updateDesignTexture()
        
        babylon.run()

        setTimeout(generateMockupViews, 500)
    }

    const generateMockupViews = async () => {
        const babylon = getBabylonInstance()
        const captures = await babylon.captureMockupCameraImages()
        setMockupViews(captures)
        dispatch(actionUpdatePreviewImages3D(captures))
    }

    const updateUVMapping = async () => {
        if (selectedSide === 'All' || selectedSide === 'Safezone') return

        const artworksData = fetchPreview3D()
        if (!artworksData) return

        for (const artwork of artworksData) {
            if (!artwork?.canvas) continue

            const templateConfigs = mockupPreviewV3Config['template_configs'][artwork.side]
            if (!templateConfigs) return
    
            const designParts = await cutDesignParts(
                artwork?.canvas,
                templateConfigs,
                mockupPreviewV3Config.texture.scale,
            )
    
            const babylon = getBabylonInstance()
            designParts.forEach((designPart, i) => {
                babylon.drawUV(designPart.image, artwork.side, i)
            })
        }

        generateMockupViews()
    }

    const switchMockupView = (index) => {
        setSelectedMockupView(index)
        const babylon = getBabylonInstance()
        babylon.updateMockupCameraPosition(index)
    }

    const debounceUpdateUVMapping = debounce(() => {
        updateUVMapping()
    }, 50)

    useEffect(() => {
        start3D()

        return () => {
            destroyBabylonInstance()
        }
    }, [])

    useEffect(() => {
        const subscriber = PubSub.subscribe('UPDATE_3D_MODEL_TEXTURE', () => {
            updateUVMapping()
        })

        return () => {
            PubSub.unsubscribe(subscriber)
        }
    }, [pickerAttributes, designs, selectedAttribute])

    useEffect(() => {
        if (loadingImageLayer) return
        debounceUpdateUVMapping()

        return () => {
            debounceUpdateUVMapping.cancel()
        }
    }, [pickerAttributes, designs, selectedAttribute, loadingImageLayer, selectedSide, selectedAttribute?.safeZone[selectedSide]?.fill])

    return (
        <div id="preview_live_artwork_container" className="PreviewLiveArtworkContainer d-flex flex-column w-100">
            <div className="d-flex justify-content-center">
                <button
                    type="button"
                    onClick={() => switchPreview('mockup')}
                    className={classNames('Preview2DButton', {active: previewMode === 'mockup'})}
                    disabled={!mockupViews.length}
                >
                    Mockup
                </button>
                <button
                    type="button"
                    onClick={() => switchPreview('3d')}
                    className={classNames('Preview3DButton', {active: previewMode === '3d'})}
                >
                    3D
                </button>
            </div>
            {!!mockupPreviewV3Config.mockup_views && (
                <div className="PreviewLiveArtworkCanvas" style={{display: previewMode === 'mockup' ? 'block' : 'none'} }>
                    <div className="d-flex flex-column h-100">
                        <div className="position-relative" style={{flexGrow: 1}}>
                            <canvas id="mockup_canvas" className="position-absolute w-100 h-100" />
                        </div>
                        <div className="d-flex justify-content-center">
                            <div style={{padding: '30px 12px', width: '292px'}}>
                                <Slider
                                    infinite
                                    slidesToShow={3}
                                    slidesToScroll={1}
                                    draggable={false}
                                    nextArrow={<ChevronRight />}
                                    prevArrow={<ChevronLeft />}
                                >
                                    {mockupViews.map((url, index) => (
                                        <div key={index}>
                                            <div
                                                className={classNames('SwitchMockupViewButton', {active: selectedMockupView === index})}
                                                onClick={() => switchMockupView(index)}
                                            >
                                                <img src={url} alt={index} className="w-100 h-100" />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div id="preview_live_artwork_canvas" className="PreviewLiveArtworkCanvas position-relative flex-column align-items-center justify-content-center"
                 style={{display: previewMode === '3d' ? 'flex' : 'none'}}>
                <canvas ref={renderCanvasRef} className="position-absolute w-100 h-100" />
            </div>
        </div>
    )
})

export default PreviewLiveArtworkContainer
