// eslint-disable-next-line import/no-anonymous-default-export
export default {
    product: null,
    attributes: [], // all attributes of product
    variants: [], // all variants of product
    pickerVariants: [], // list variants has picked
    pickerAttributes: [], // list attributes has picked
    selectedAttribute: null, // current attribute selected
    selectedSide: null, // current side design selected
    mappedAttributes: {}, // attributes for select box
    attributeMockups: [], // attributes transform from mockup
    designs: [],
    history: null,
    display: 'design',
    tabDesign: '',
    isAdmin: false,
    selectedLayer: null,
    stageDrag: false,
    reloadBgPreview: false,
    // stageRef: null,

    editorWrapper: {
        width: 0,
        height: 0,
    },

    errors: {},
    loadingMockupPreview: false,
    previewImages: [],
    previewImages3D: [],
    listBackgroundImages: [],
    currentBgImage: {},
    currentBgImageType: 'original',
    currentBgImageBase64: null,
    galleryImage: null,
    productDetail: {
        description: '',
        collections: [],
        tags: [],
        is_stealthy: false,
        is_featured: false,
        is_shop_hidden: false,
        is_collection_hidden: false,
        meta: {
            facebook_pixel_id: '',
            facebook_conversion: '',
        },
        split_product: false,
        attributes: [],
        variants: [],
        slug: '',
    },
    runningImageCompress: false,
    imageCompressProgress: 0,
    copyLayer: null,
    reloadPreview: true,
    applyBgAllSide: false,
    loadingImageLayer: false,
}

