import React, {useEffect, useRef} from 'react'
import {useDragAndDropFile} from '../../../hooks/useDragAndDropFile'
import useUploadFile from '../../../hooks/useUploadFile'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'
import {
    actionCopyLayer,
    actionPasteLayer,
    actionRedo,
    actionSetRunningImageCompress,
    actionUndo,
    actionUpdateSizeEditorWrapper,
    removeLayer,
    updateLayerAttribute,
} from '../context/SelectedVariantContext/action'
import {hideToolbar, curriedMoveLayer as moveLayer, isMacOs} from '../context/SelectedVariantContext/helper'
import {useEditorSides} from '../../../hooks/useEditorSides'
import {useEditorBackground} from '../../../hooks/useEditorBackground'
import {MOVE_LAYER_DIRECTION_KEY, MOVE_LAYER_STEP_NUMBER} from '../context/SelectedVariantContext/constants'
import {useEditorLayers} from '../../../hooks/useEditorLayers'
import {ReactComponent as Upload} from '../../../assets/icons/upload.svg'

const EditorWrapper = ({children, classes, ...rest}) => {
    const editorWrapperRef = useRef(null)
    const {onDragOver, onDrop} = useDragAndDropFile()
    const {uploadFile} = useUploadFile()
    const layers = useEditorLayers()
    const fileInputRef = useRef(null);
    const [loading, setLoading] = React.useState(false);

    /**
     * @type {State} state
     */
    const {
        state: {selectedLayer, stageDrag, loadingImageLayer, runningImageCompress, selectedAttribute, selectedSide},
        dispatch,
    } = useSelectedVariantContext()
    const {pending} = useUploadFile()
    const background = selectedAttribute?.safeZone[selectedSide]

    const {ratioDefault} = useEditorBackground()
    const {isOptionalSide} = useEditorSides()

    useEffect(() => {
        const handleInitialResize = () => {
            if (editorWrapperRef.current) {
                setLoading(true)
                const wraper = document.querySelector('.Step2Container  .Wrapper')
                const leftEditor = document.querySelector('.Step2Container  .Wrapper > .Left')
                const toolbarEditor = document.querySelector('.Step2Container  .Wrapper > .Toolbar')
                let editorWidth = wraper.offsetWidth
                if (leftEditor) {
                    editorWidth -= (leftEditor.offsetWidth + 160)
                }
                if (toolbarEditor) {
                    editorWidth -= toolbarEditor.offsetWidth
                }
                // if (rightbarEditor) {
                //     editorWidth -= rightbarEditor.offsetWidth
                // }
                console.log(editorWidth)
                let {offsetHeight} = editorWrapperRef.current
                dispatch(actionUpdateSizeEditorWrapper({width: editorWidth, height: offsetHeight}))
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            }
        }
        handleInitialResize()
        window.addEventListener('resize', handleInitialResize)
        return () => {
            window.removeEventListener('resize', handleInitialResize)
        }
    }, [dispatch])

    const handleDrop = (e) => {
        if (isOptionalSide) return
        dispatch(actionSetRunningImageCompress(true))
        const file = onDrop(e)
        return uploadFile(file)
    }

    const handleClick = () => {
        fileInputRef.current.click();
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            dispatch(actionSetRunningImageCompress(true))
            return uploadFile(file)
        }
    };

    useEffect(() => {
        if (!selectedLayer) {
            hideToolbar()
        }
    }, [selectedLayer])

    // keyboard events
    useEffect(() => {
        const handleMoveLayer = (key) => {
            const move = moveLayer(key)
            const moveWithDefaultStep = move(MOVE_LAYER_STEP_NUMBER)
            dispatch(updateLayerAttribute(moveWithDefaultStep(selectedLayer)))
        }

        const handleKeyDown = (event) => {
            const isMacOS = isMacOs()
            const {key, ctrlKey, metaKey, keyCode, shiftKey} = event || {}
            const deleteKeysCombination =
                (ctrlKey && key === 'Backspace') ||
                key === 'Delete' ||
                (metaKey && key === 'Backspace') ||
                (metaKey && key === 'Delete')
            if (selectedLayer && deleteKeysCombination) {
                if (selectedLayer.isLock) return
                dispatch(removeLayer(selectedLayer))
                hideToolbar()
            }
            if (
                (ctrlKey && keyCode === 90 && shiftKey) ||
                (isMacOS && keyCode === 90 && (metaKey || ctrlKey) && shiftKey)
            ) {
                hideToolbar()
                dispatch(actionRedo())
            } else if ((ctrlKey && event.key === 'z') || (isMacOS && key === 'z' && (metaKey || ctrlKey))) {
                hideToolbar()
                dispatch(actionUndo())
            } else if ((ctrlKey && key === 'c') || (isMacOS && key === 'c' && (metaKey || ctrlKey))) {
                dispatch(actionCopyLayer(selectedLayer))
            } else if (
                !loadingImageLayer &&
                ((ctrlKey && key === 'v') || (isMacOS && key === 'v' && (metaKey || ctrlKey)))
            ) {
                dispatch(actionPasteLayer())
            } else if (ctrlKey && (key === '+' || key === '-' || key === '=')) {
                event.preventDefault()
            } else if (Object.values(MOVE_LAYER_DIRECTION_KEY).includes(key)) {
                const layerLocked = selectedLayer && selectedLayer.isLock
                const unselectedLayer = !selectedLayer
                if (layerLocked || unselectedLayer) return
                handleMoveLayer(key)
            }
        }

        const handleWheel = (event) => {
            if (event.ctrlKey) {
                event.preventDefault()
            }
        }

        const handleResize = (event) => {
            event.preventDefault()
        }

        window.addEventListener('keydown', handleKeyDown)
        window.addEventListener('resize', handleResize)
        window.addEventListener('wheel', handleWheel, {passive: false})
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('wheel', handleWheel)
        }
    }, [dispatch, selectedLayer, ratioDefault, loadingImageLayer])

    return (
        <div
            {...rest}
            className={classes}
            ref={editorWrapperRef}
            style={{
                opacity: loading ? 0 : 1,
                cursor: stageDrag ? 'grab' : 'default',
            }}
            onDragOver={onDragOver}
            onDrop={handleDrop}
        >
            {children}
            {
                (layers.length === 0 && background && !pending && !runningImageCompress) && (
                    <div className='ClickToUpload' onClick={handleClick}>
                        <div>
                            <Upload width={24} height={24}/>
                        </div>
                        Drag, drop or upload your design (Max {background.width} x {background.height} px)
                        <input
                            type="file"
                            ref={fileInputRef}
                            className="d-none"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default EditorWrapper
