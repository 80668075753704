import {useCallback, useEffect, useState} from 'react'
import Axios from 'axios'
import {getLocalData} from '../services/StorageServices'
import {getArtworkFonts} from '../services/ArtworkTemplateServices'
import {convertToVariantsArrayForExternalLibrary} from '../helpers/fonts'

const useFontSelector = () => {
    const [fonts, setFonts] = useState({
        customFonts: [],
        googleFonts: [],
    })

    const _getGoogleFonts = async () => {
        const googleFonts = await Axios.get(
            `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_FONT_API_KEY}`,
        )

        return googleFonts.data.items
    }

    const renderFontOption = useCallback(async () => {
        let parseCustomFonts = {},
            googleFontItem = {}

        const googleFonts = fonts?.googleFonts
        googleFonts.forEach((googleFont) => {
            const {family, files, subsets, category, variants} = googleFont
            const convertedVariantsArrayForExternalLibrary = convertToVariantsArrayForExternalLibrary(variants)

            const replaceAttribute = (i, newI) => {
                files[newI] = files[i]
                delete files[i]
            }

            const replaceToHttps = (string) => string.replace('http://', 'https://')

            for (const i in files) {
                let newIndex = null
                if (i.indexOf('italic') > -1 && i.length > 6) {
                    newIndex = i.replace('italic', 'i')
                } else if (i === 'italic') newIndex = '400i'
                else if (i === 'regular') newIndex = '400'

                newIndex && replaceAttribute(i, newIndex)
                files[newIndex || i] = replaceToHttps(files[newIndex || i])
            }

            googleFontItem[family] = {
                category,
                files,
                variants: convertedVariantsArrayForExternalLibrary.join(','),
                subsets: subsets.join(','),
            }
        })

        const accessToken = getLocalData('accessToken')
        if (!accessToken) {
            const customFonts = !!sessionStorage.getItem('customFonts')
                ? JSON.parse(sessionStorage.getItem('customFonts'))
                : {}
            setFonts({
                customFonts: customFonts,
                googleFonts: googleFontItem,
            })
        } else {
            try {
                const {success, data, message} = await getArtworkFonts()

                if (!success) throw new Error(message)

                data.forEach((item) => {
                    parseCustomFonts[item.displayName] = {
                        category: 'other',
                        files: item.path,
                        variants: '',
                        subsets: 'latin',
                    }
                })
                const accessToken = getLocalData('accessToken')
                if (!accessToken) {
                    const customFonts = !!sessionStorage.getItem('customFonts')
                        ? JSON.parse(sessionStorage.getItem('customFonts'))
                        : {}
                    setFonts({
                        customFonts: customFonts,
                        googleFonts: googleFontItem,
                    })
                } else {
                    try {
                        const {success, data, message} = await getArtworkFonts()

                        if (!success) throw new Error(message)

                        data.forEach((item) => {
                            parseCustomFonts[item.displayName] = {
                                category: 'other',
                                files: item.path,
                                variants: '',
                                subsets: 'latin',
                            }
                        })
                        setFonts({
                            customFonts: parseCustomFonts,
                            googleFonts: googleFontItem,
                        })
                    } catch (e) {
                        // window.alert(e.message)
                        console.error(e.message)
                    }
                }
                setFonts({
                    customFonts: parseCustomFonts,
                    googleFonts: googleFontItem,
                })
            } catch (e) {
                // window.alert(e.message)
                console.error(e.message)
            }
        }
    }, [fonts])

    useEffect(() => {
        const parseFont = async () => {
            const ggFonts = await _getGoogleFonts()
            let parseCustomFonts = {},
                googleFontItem = {}

            const googleFonts = ggFonts
            googleFonts.forEach((googleFont) => {
                const {family, files, subsets, category, variants} = googleFont
                const convertedVariantsArrayForExternalLibrary = convertToVariantsArrayForExternalLibrary(variants)

                const replaceAttribute = (i, newI) => {
                    files[newI] = files[i]
                    delete files[i]
                }

                const replaceToHttps = (string) => string.replace('http://', 'https://')

                for (const i in files) {
                    let newIndex = null
                    if (i.indexOf('italic') > -1 && i.length > 6) {
                        newIndex = i.replace('italic', 'i')
                    } else if (i === 'italic') newIndex = '400i'
                    else if (i === 'regular') newIndex = '400'

                    newIndex && replaceAttribute(i, newIndex)
                    files[newIndex || i] = replaceToHttps(files[newIndex || i])
                }

                googleFontItem[family] = {
                    category,
                    files,
                    variants: convertedVariantsArrayForExternalLibrary.join(','),
                    subsets: subsets.join(','),
                }
            })
            setFonts({
                ...fonts,
                customFonts: parseCustomFonts,
                googleFonts: googleFontItem,
            })
        }
        parseFont()
    }, [])

    return {
        renderFontOption,
        fonts,
    }
}

export default useFontSelector
