import {useSelectedVariantContext} from '../components/step2/context/SelectedVariantContext'
import {updateLayerAttribute} from '../components/step2/context/SelectedVariantContext/action'

const useImagePattern = () => {
    const {
        dispatch,
        state: {selectedLayer},
    } = useSelectedVariantContext()

    const disablePattern = () => {
        if (!selectedLayer) return
        dispatch(
            updateLayerAttribute({
                ...selectedLayer,
                hasPattern: false,
            }),
        )
    }

    const togglePattern = () => {
        if (!selectedLayer) return
        dispatch(
            updateLayerAttribute({
                ...selectedLayer,
                hasPattern: !selectedLayer.hasPattern,
            }),
        )
    }

    const onChangeImagePattern = (pattern) => {
        dispatch(
            updateLayerAttribute({
                ...selectedLayer,
                hasPattern: true,
                patternType: pattern,
            }),
        )
    }

    const onChangePatternSpacing = (direction) => (value) => {
        dispatch(
            updateLayerAttribute({
                ...selectedLayer,
                [direction]: value,
            }),
        )
    }

    return {
        hasPattern: selectedLayer?.hasPattern,
        togglePattern,
        disablePattern,
        onChangePatternSpacing,
        onChangeImagePattern,
    }
}

export default useImagePattern
