import Axios from 'axios'
import _ from 'lodash'
import Bluebird from 'bluebird'
import JSZip from 'jszip'
import React from 'react'
import {ReactComponent as ImageDownload} from '../../../../assets/icons/download-image.svg'
import {useSelectedVariantContext} from '../../context/SelectedVariantContext'
import {isSubsetAttributes} from '../../context/SelectedVariantContext/helper'
import PFButton from '../../../../shared/PFButton'
import {base64ToFile} from '../../../../helpers/imageFormat'
import {downloadMockup} from '../../../../services/ArtworkTemplateServices'
import {toaster} from '../../../../shared/PFToast'

const PreviewArtworkDownload = () => {
    const {state} = useSelectedVariantContext()
    const {product, previewImages, selectedAttribute, currentBgImageBase64} = state
    const {mockups} = product
    let mockupInfos = _.get(mockups[0], 'meta.mockup_infos', [])
    const bgDefault = mockupInfos[0].parts[0].image_path

    const _handleDownload = async (type = 'single') => {
        let images = []
        if (type === 'single') {
            const selectedAttr = selectedAttribute.attributes.map((attr) => {
                return {type: attr.type, value: attr.value_code}
            })
            images =
                previewImages.find((preview) => isSubsetAttributes(preview.attributes, selectedAttr))?.images || []


        } else {
            images = previewImages.reduce((acc, preview) => [...acc, ...preview.images], [])
        }
        const formData = new FormData()
        if (currentBgImageBase64) {
            const bgFile = await base64ToFile(currentBgImageBase64, 'background-image.jpg','text/plain');
            formData.append('mockups', JSON.stringify(images))
            formData.append('background', bgFile)
        } else {
            formData.append('mockups', JSON.stringify(images))
            formData.append('background', bgDefault)
        }

        const response = await downloadMockup(formData)

        const {success, message, data} = response
        if (!success) {
            return toaster({
                type: 'error',
                message: message,
            })
        }
        const zip = new JSZip()
        await Bluebird.map(data, async (image, index) => {
            const response = await Axios.get(image, {responseType: 'blob'})
            const fileName = `mockup_${index}.jpg`
            const blob = new Blob([response.data], {type: 'image/jpeg'})
            zip.file(fileName, blob)
        })

        zip.generateAsync({type: 'blob'}).then((content) => {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(content)
            link.download = `${product.title}_mockup.zip`
            link.click()
        })
    }

    if (!previewImages?.length) return null
    return (
        <div className="PreviewArtworkDownload">
            <PFButton
                buttonText={
                    <div className="d-flex align-items-center">
                        <ImageDownload width={20} className="me-1" />
                        Download
                    </div>
                }
                onClick={() => _handleDownload('single')}
                className="d-flex align-items-center me-3"
            />
            {previewImages.length > 1 && (
                <PFButton
                    buttonText={
                        <div className="d-flex align-items-center">
                            <ImageDownload width={20} className="me-1" />
                            Download all
                        </div>
                    }
                    onClick={() => _handleDownload('multiple')}
                    className="d-flex align-items-center me-3"
                />
            )}
        </div>
    )
}

export default PreviewArtworkDownload

