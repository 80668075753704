import React, {useEffect, useState} from 'react'
import {Button, ButtonGroup} from 'reactstrap'
import {useSelectedVariantContext} from '../../../context/SelectedVariantContext'
import DesignLibraryModal from './image-library/DesignLibraryModal'
import {
    actionUpdateBgImage,
    actionUpdateBgImageType,
    actionUpdateLoadingBackground
} from '../../../context/SelectedVariantContext/action'
import {ReactComponent as PlusIcon} from '../../../../../assets/icons/plus.svg'
import {ReactComponent as WarningIcon} from '../../../../../assets/icons/warning_amber.svg'
import {getInfomationBackground} from '../../../../../helpers/createProductCombine'

const UploadBackgroundImage = () => {
    const {
        state: {listBackgroundImages, currentBgImage, currentBgImageType},
        dispatch
    } = useSelectedVariantContext()
    const [wPreview, setWPreview] = useState(1000)
    const [hPreview, setHPreview] = useState(800)
    const options = [
        { value: 'original', label: 'Original' },
        { value: 'fit', label: 'Fit and center' },
        { value: 'fill', label: 'Scale to Fill' },
    ]
    const [isOpenImageDesign, setIsOpenImageDesign] = useState(false)

    const _toggle = () => {
        setIsOpenImageDesign((prev) => !prev)
    }

    const onChangeBackgroundType = (value) => {
        const info = getInfomationBackground(value, currentBgImage, wPreview, hPreview)
        const {width, height, x, y} = info
        const backgroundImage = {
            ...currentBgImage,
            width,
            height,
            x,
            y,
        }
        dispatch(actionUpdateBgImage(backgroundImage))
        dispatch(actionUpdateBgImageType(value))
    }

    const onSelectBackgroundImage = (image = null) => {
        if (!!image) {
            const info = getInfomationBackground(currentBgImageType, image, wPreview, hPreview)
            const {width, height, x, y} = info
            const newBackgroundImage = {
                ...currentBgImage,
                ...image,
                width,
                height,
                x,
                y,
            }
            if (currentBgImage.key !== image.key) dispatch(actionUpdateLoadingBackground(true))
            dispatch(actionUpdateBgImage(newBackgroundImage))
        } else {
            dispatch(actionUpdateBgImage({}))
        }
    }

    const getInfoPreviewArtworkSlider = () => {
        let w = 0
        let h = 0
        if (document.querySelector('.PreviewArtworkContainer')) {
            w = document.querySelector('.PreviewArtworkContainer').offsetWidth
            h = document.querySelector('.PreviewArtworkContainer').offsetHeight
        }
        setWPreview(w)
        setHPreview(h)
    }

    useEffect(() => {
        getInfoPreviewArtworkSlider()
        window.addEventListener('resize', getInfoPreviewArtworkSlider)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(async () => {
    //     //getInfoPreviewArtworkSlider()
    //     //window.addEventListener('resize', getInfoPreviewArtworkSlider)
    // }, [])

    return (
        <div className="UploadBackgroundImage">
            <h4>Mockup background</h4>
            <p>
                Recommended resolution 2000 x 2000 px (72 DPI).<br />Supported PNG, JPG, SVG files. <br />Maximum file
                storage 50MB
            </p>
            {(currentBgImage && (currentBgImage?.naturalHeight < 2000 && currentBgImage?.naturalWidth < 2000)) && (
                <p className="alert-warning"><WarningIcon /> Images with a resolution lower than 2000 x 2000 pixels may
                    appear blurry, which could also impact the ranking of your listing</p>
            )}
            <div className='listBackgroundImages'>
                <div id="upload_mockup_background" className='itemUploadBackgroundImage selectImage flex-column' onClick={_toggle}>
                    <div className="mb-1"><PlusIcon /></div>
                    Upload
                </div>
                <div
                    className={currentBgImage.key === undefined ? 'itemUploadBackgroundImage active' : 'itemUploadBackgroundImage'}
                    onClick={() => onSelectBackgroundImage()}>
                    Default
                </div>
                {listBackgroundImages.length > 0 && listBackgroundImages.map((item) => {
                    return <div
                        className={currentBgImage.key === item.key ? 'itemUploadBackgroundImage active' : 'itemUploadBackgroundImage'}
                        onClick={() => onSelectBackgroundImage(item)}>
                        <img src={item?.file} alt="" />
                    </div>
                })}
            </div>
            {currentBgImage.file !== undefined && (
                <div className="GroupButtonType d-flex">
                    <ButtonGroup
                        className="my-2"
                    >
                    {options.map(item => {
                        return <Button id="effect_mockup_background" className={`${currentBgImageType === item.value ? 'active' : ''}`} outline onClick={() => onChangeBackgroundType(item.value)}>
                            {item.label}
                        </Button>
                    })}
                    </ButtonGroup>
                </div>
            )}
            {isOpenImageDesign && <DesignLibraryModal isOpen={isOpenImageDesign} toggle={_toggle} wPreview={wPreview}
                                                      hPreview={hPreview} />}
        </div>
    )
}

export default UploadBackgroundImage

