import {useMemo} from 'react'
import {useSelectedVariantContext} from '../components/step2/context/SelectedVariantContext'
import {mergeAttributesByDesign} from '../components/step2/context/SelectedVariantContext/helper'

export function useEditorStageSides() {
    const {
        state: {designs, pickerAttributes},
    } = useSelectedVariantContext()

    const designsSpecified = designs.filter((design) => design.isDefault === false)
    let attIds = []
    designsSpecified.forEach((design) => {
        design.linkedAttrIds.forEach((id) => {
            if (!attIds.includes(id)) {
                attIds.push(id)
            }
        })
    })

    const stageSides = useMemo(() => {
        if (!designs.length || !pickerAttributes.length) return []
        const attrsData = mergeAttributesByDesign(pickerAttributes, designs)

        return attrsData.map((attributes) => {
            const attribute = attributes[0]
            const side = attribute.safeZone
            const sides = Object.keys(side)
            const design = designs.find((item) => item.linkedAttrIds.includes(attribute._id))
            if(!design) return []

            return sides.map((side) => ({
                ...design.side[side],
                background: attribute.safeZone[side],
                ref: attribute.ref[side],
                side,
                attributeIds: attributes.map((attribute) => attribute._id),
            }))
        })
    }, [designs, pickerAttributes])

    return stageSides
}

